//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InfoIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Tooltip } from "@chakra-ui/react";
import React from "react";

export const InfoTooltip = ({ label, boxProps }: { label: string | React.ReactNode; boxProps?: BoxProps }) => {
  return (
    <Tooltip hasArrow label={label} placement="top">
      <Box
        position="relative"
        padding="2"
        display="flex"
        justifyContent="center"
        color="gray.600"
        _hover={{
          color: "gray.800",
        }}
        {...boxProps}
      >
        <InfoIcon />
      </Box>
    </Tooltip>
  );
};
