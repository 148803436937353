//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import React from "react";
import { Loader } from "semantic-ui-react";
import useProject from "./useProject";
import { TextLink } from "../../ui/lib";

interface IProjectLink {
  id?: string;
  onSelect: () => void;
}

const ProjectLink = ({ id, onSelect }: IProjectLink) => {
  const { loading, project } = useProject(id || "");

  if (loading) return <Loader active inline size="tiny" data-testid="projectLoader" />;

  return (
    <TextLink disabled={!project} onClick={onSelect}>
      {(project && project.name) || "-"}
    </TextLink>
  );
};

export default ProjectLink;
