//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import SingleSelectControl from "../../components/SingleSelectControl";
import { useFetchIPAllowlists } from "./useFetchIPAllowlists";

export const IPAllowlistSelector = () => {
  const { data: ipAllowlists } = useFetchIPAllowlists();
  return (
    <SingleSelectControl
      label="IP Allowlist"
      name="ipAllowlistId"
      selectProps={{
        options: ipAllowlists?.items?.map((ipAllowlist) => ({
          label: ipAllowlist.name || "",
          value: ipAllowlist.id || "",
        })),
        isClearable: true,
      }}
      tooltip="Select an optional IP allowlist to limit access to your deployment. Note that while an IP allowlist is optional, using it is highly recommend."
    />
  );
};
