//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { default as ReactSelect, SelectInstance } from "react-select";
import { getSelectBase, OptionType, SelectBaseProps } from "./SelectBase";

const SingleSelectBase = getSelectBase<false>(ReactSelect);

export const SingleSelect = React.forwardRef((props: SelectBaseProps<false>, ref: React.Ref<SelectInstance<OptionType, false>>) => (
  <SingleSelectBase {...props} ref={ref} />
));
