//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Websocket from "react-websocket";
import Auth from "../auth/Auth";
import { AuthContextProvider } from "../auth/AuthContextProvider";
import { ITracking } from "../tracking/api";
import { EventSubscriptionManager } from "../util/EventSubscriptionManager";
import { PermissionCache } from "../util/PermissionCache";
import { WithRefreshContextProvider, useWithRefresh } from "../util/WithRefreshContext";
import Dashboard from "./Dashboard";
import { TrackingContextProvider } from "../tracking/TrackingContextProvider";

// Interface decribing the properties of the dashboard bootstrap component
interface IDashboardBootstrapProps extends RouteComponentProps {
  onClickLogout: () => void;
  permissionCache: PermissionCache;
  auth: Auth;
  tracking: ITracking;
}

// Interface decribing the state of the dashboard bootstrap component
interface IDashboardBootstrapState {
  eventSubscriptionManager: EventSubscriptionManager;
}

// Interface describing the properties for the dasboard bootstrap view
interface IDashboardBootstrapViewArgs extends RouteComponentProps {
  onClickLogout: () => void;
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
  onWebsocketRef: (x: any) => void;
  auth: Auth;
  tracking: ITracking;
}

const DashboardBootstrapView = ({ ...args }: IDashboardBootstrapViewArgs) => {
  const { eventSubscriptionManager } = useWithRefresh();
  return (
    <div>
      <Websocket url={eventSubscriptionManager.webSocketURL()} onMessage={eventSubscriptionManager.handleServerEvent} ref={args.onWebsocketRef} />
      <Dashboard {...args} loading={false} />
    </div>
  );
};

class DashboardBootstrap extends Component<IDashboardBootstrapProps, IDashboardBootstrapState> {
  state = {
    eventSubscriptionManager: new EventSubscriptionManager(this),
  } as IDashboardBootstrapState;

  refWebSocket: any;

  getWebSocket = (): any => {
    return this.refWebSocket;
  };

  setWebSocket = (x: any): any => {
    this.refWebSocket = x;
  };

  componentDidMount() {
    this.props.tracking.setWebSocketRef(this);
  }

  componentWillUnmount() {
    this.props.tracking.setWebSocketRef(undefined);
    this.state.eventSubscriptionManager.stopTimer();
    this.state.eventSubscriptionManager.closeWSConnection();
  }

  render() {
    return (
      <TrackingContextProvider tracking={this.props.tracking}>
        <AuthContextProvider auth={this.props.auth}>
          <WithRefreshContextProvider
            eventSubscriptionManager={this.state.eventSubscriptionManager}
            permissionCache={this.props.permissionCache}
            loading={false}
          >
            <DashboardBootstrapView {...this.props} {...this.state} onWebsocketRef={this.setWebSocket} />
          </WithRefreshContextProvider>
        </AuthContextProvider>
      </TrackingContextProvider>
    );
  }
}

// Notice that here withRefresh() is not used, this is because the Dashboard class creates the
// eventSubscriptionManager which is used as input property for all (child) components.
// This component is not using ReactTimeout, instead it's using the eventSubscriptionManager created here.
export default DashboardBootstrap;
