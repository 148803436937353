//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormErrorMessage, Grid } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { Props as ReactSelectProps, PropsValue } from "react-select";
import LabeledFormControl, { LabeledFormControlProps } from "./LabeledFormControl";
import { MultiSelect } from "./MultiSelect";
import { OptionType } from "./SelectBase";
import { SingleSelect } from "./SingleSelect";

interface SelectControlProps extends LabeledFormControlProps {
  label: string;
  name: string;
  selectProps?: ReactSelectProps<OptionType, false>;
}

export const SingleSelectControl: React.FC<SelectControlProps> = ({ label, name, selectProps, children, ...rest }) => {
  const [field, { error, touched }, helper] = useField(name);

  const { isSubmitting } = useFormikContext();
  const value =
    // memoize the value to prevent option re-focus
    useMemo(() => {
      return (
        (selectProps?.options?.find((option) => {
          return (option as OptionType).value === field.value;
        }) as PropsValue<OptionType>) || null
      );
    }, [field.value, selectProps?.options]);

  return (
    <LabeledFormControl label={label} htmlFor={name} isInvalid={!!error && touched} {...rest} isDisabled={rest.isDisabled || isSubmitting}>
      <Grid gridTemplateRows="1fr auto">
        <SingleSelect
          {...field}
          value={value}
          inputId={name}
          menuPlacement="auto"
          isError={!!error && touched}
          {...selectProps}
          // note: we pass isDisabled after selectProps to prevent it from being overridden and isSubmitting is taken into account
          isDisabled={rest.isDisabled || isSubmitting}
          onChange={(value) => {
            helper.setValue(value?.value);
          }}
          menuPortalTarget={document.body}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
        {children}
      </Grid>
    </LabeledFormControl>
  );
};

export type MultiSelectControlProps = LabeledFormControlProps & {
  label: string;
  name: string;
  selectProps?: ReactSelectProps<OptionType, true>;
};
export const MultiSelectControl = (props: MultiSelectControlProps) => {
  const { name, label, selectProps, ...rest } = props;
  const [field, { error, touched }, helper] = useField(name);
  const { isSubmitting } = useFormikContext();

  const value = selectProps?.options?.filter((option) => {
    return field.value?.includes((option as OptionType).value);
  }) as PropsValue<OptionType>;
  return (
    <LabeledFormControl label={label} htmlFor={name} isInvalid={!!error && touched} {...rest} isDisabled={rest.isDisabled || isSubmitting}>
      <MultiSelect
        {...field}
        value={value}
        inputId={name}
        isDisabled={rest.isDisabled || isSubmitting}
        onChange={(values) => {
          const valueStringArray = values?.map((value) => {
            return value.value;
          });
          helper.setValue(valueStringArray);
        }}
        {...selectProps}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </LabeledFormControl>
  );
};
