//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button, Card, Dropdown, Form, Header, Label, Menu, Segment, Statistic, Grid } from "semantic-ui-react";
import { FlexBox } from "./_flex";

const objektivFont = css`
  font-family: var(--font-family);
`;

const menuBackground = css`
  background-color: var(--gray-950) !important;
`;
const menuForeground = css`
  color: var(--gray-500) !important;
  font-family: var(--font-family) !important;
`;

const leftMarginHeaderContent = css`
  margin-left: calc(6.25% + 8px);
  @media (max-width: 768px) {
    margin-left: calc(6.25% + 8px);
  }
`;

const shrinkText = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const yellowUnderlineBlock = css`
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  background-color: var(--green-600);
`;

export const FieldSet = styled(Segment)`
  padding-top: 0.3em;
  padding-bottom: 0.3em;
`;

export const Field = styled("div")`
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  clear: both;
`;

export const FieldLabel = styled("span")`
  width: 9rem;
  display: inline-block;
  float: left;
`;

export const FieldLabelWide = styled("span")`
  width: 18rem;
  display: inline-block;
  float: left;
`;

export const FieldContent = styled("div")`
  display: inline-block;
`;

export const FieldIcons = styled("div")`
  float: right;
`;

export const LoaderBox = styled("span")`
  float: right;
  width: 0;
  padding-right: 1em;
  margin-right: 1em;
  margin-top: 1em;
  max-width: 0;
  display: inline-block;
`;

export const LoaderBoxForTable = styled("span")`
  float: right;
  width: 0;
  padding-right: 1em;
  max-width: 0;
  display: inline-block;
`;

export const StretchedButtonGroup = styled(Button.Group)`
  width: 100%;
`;

export const ButtonsCardContent = styled(Card.Content)`
  padding: 0 !important;
  border: none !important;
`;

export const DashboardCard = styled(Card)`
  .provider,
  .region,
  .endpoint,
  .logo {
    padding: 1em;
  }
  .endpoint {
    ${shrinkText}
  }
`;

export const DeploymentCard = styled(Card)`
  .provider,
  .region,
  .endpoint,
  .logo {
    padding: 1em;
  }
  .endpoint {
    ${shrinkText}
  }
`;

export const FloatRight = styled("div")`
  float: right;
`;

export const FloatLeft = styled("div")`
  float: left;
`;

export const StyledMenu = styled(Menu)`
  width: 15rem !important;
  @media (max-width: 768px) {
    width: 10rem !important;
  }
`;

export const TopMenuOnboarding = styled("div")`
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  text-align: right;
  padding-right: 16px;
  padding-top: 16px;
  > a {
    margin-right: 24px;
  }
`;

export const TopMenuUserOnboarding = styled("div")`
  display: inline-block;
`;

export const TopMenuContentBack = styled("div")`
  ${objektivFont}
  position: fixed;
  margin-top: 16px;
  height: 20px;
  color: var(--gray-600);
  font-size: 14px;
  line-height: 20px;
  margin-left: 146px;
  @media (max-width: 768px) {
    margin-left: 48px;
  }
  width: calc(6.25% + 42px + 90px - 146px);
  @media (max-width: 768px) {
    width: calc(6.25% + 32px - 146px);
  }
  > span {
    display: flex;
  }
  .back-text {
    flex-grow: 0;
  }
  .back-filler {
    border-bottom: 1px solid var(--gray-300);
    max-height: 12px;
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const MenuBreadCrumbText = styled("div")<{ marginLeft?: string; marginRight?: string }>`
  ${objektivFont}
  margin-top: 16px;
  height: 20px;
  color: var(--gray-600);
  font-size: 14px;
  line-height: 20px;
  margin-left: 80px;
  @media (max-width: 768px) {
    margin-left: ${(props) => props.marginLeft || "calc(6.25% - 88px);"};
  }
  margin-right: ${(props) => props.marginRight || "320px"};
  ${shrinkText}
`;

export const MenuTitleText = styled("div")`
  ${objektivFont}
  min-height: 46px;
  color: var(--gray-950);
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
  @media (max-width: 768px) {
    margin-left: calc(6.25% - 88px);
  }
  ${shrinkText}

  .subTitle {
    line-height: normal;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const TopMenuUser = styled("div")`
  width: 150px;
  margin-left: auto;
`;

export const MenuIndent = styled("span")`
  display: block;
  margin-left: 12px;
`;

export const ContentBelowMenu = styled("div")`
  margin-top: 4em;
`;

export const MainContent = styled("div")`
  margin-top: ${(props: { marginTop?: string }) => props.marginTop || "16px"};
  .menu {
    margin-bottom: 24px;
  }
`;

export const Footer = styled("div")`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 320px);
  padding: 0.2em;
  height: 2em;
  text-align: center;
  background-color: var(--gray-950);
  color: var(--gray-200);
`;

export const ImageFull = styled("img")`
  width: 100%;
  height: 100%;
`;

export const DeploymentLogo = styled("div")`
  height: 64px;
  width: 64px;
  margin: auto;
`;

export const DeploymentListLogoWithRegion = styled(FlexBox)`
  img {
    width: 48px;
    padding-right: 1em;
  }
`;

export const SidebarMenu = styled(Menu)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0 !important;
  min-height: 100%;
  width: 320px !important;
  ${menuBackground}
  border-radius: 0 !important;
  border-style: none !important;
  box-shadow: none !important;
  @media (max-width: 768px) {
    width: 10rem !important;
  }
  .icon {
    color: var(--green-600) !important;
  }
  a.item, .menu .title {
    ${menuForeground}
    ${shrinkText} 
    font-size: 14px !important;
    line-height: 36px !important;
    height: 36px !important;
    & .active {
      background-color: var(--gray-950) !important;
      color: var(--gray-500) !important;
    }
  }
  div.item {
    padding-left: 16px !important;
  }
  div.item.header {
    ${objektivFont};
    font-weight: bold;
    font-size: 12px !important;
    color: var(--green-600) !important;
    text-transform: uppercase;
    margin-top: 24px !important;
    margin-bottom: 8px !important;
  }
  a.item, div.item.header {
    padding: 0px 24px !important;
  }
  .item>.menu {
    position: fixed;
    z-index: 1;
    overflow: auto;
    height: calc(100% - 176px - 2em); /* subtract heights of logo and org and footer */
    width: 320px;
    @media (max-width: 768px) {
      width: 10rem;
    }
  }
  a.item {
    color: var(--gray-200) !important;
  }
  .ui.accordion {
    margin: 0 !important;
    padding-left: 16px; !important;
  }
  .content.active {
    padding-top: 0 !important;
  }
  #orgDropdownId {
    ${menuForeground}
    font-size: 14px;	
    font-weight: bold;	
    line-height: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    >.text {
      max-width: 90%;
      display: block;
      ${shrinkText}
    }
    >.icon {
      font-weight: normal;	
      position: absolute;
      top: 0;
      right: 0;
      color: inherit !important;
    }
    >.menu {
      font-size: 14px !important;
      line-height: 24px;
    }
  }
`;

export const OnboardingLogo = styled("div")`
  height: 150px;
  margin: auto;
`;

export const OnboardingName = styled("h2")`
  ${objektivFont}
  margin-top: 24px !important;
  color: var(--gray-950);
  font-size: 36px;
  font-weight: bold;
  line-height: 56px;
  text-align: center;
  max-width: 640px;
  margin: auto;
`;

export const OnboardingDescription = styled("div")`
  ${objektivFont}
  margin-top: 24px !important;
  color: var(--gray-950);
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  max-width: 640px;
  margin: auto;
`;

export const OnboardingFootNote = styled("div")`
  ${objektivFont}
  margin-top: 96px !important;
  color: var(--gray-950);
  font-size: 16px;
  font-style: italic;
  line-height: 25px;
  text-align: center;
  margin: auto;
`;

export const OnboardingButton = styled("div")`
  margin-top: 48px;
  display: inline-block;
`;

export const CommonKeyValuePairs = styled("div")`
  ${leftMarginHeaderContent}
  margin-top: 24px;
`;

export const CommonKeyValuePair = styled("div")`
  margin-right: 16px;
  display: inline-block;
`;

export const CommonKV_Key = styled("div")`
  ${objektivFont}
  color: var(--gray-500);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  &::after {
    ${yellowUnderlineBlock}
  }
`;

export const CommonKV_Value = styled("div")`
  ${objektivFont}
  height: 28px;
  color: var(--gray-950);
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
`;

export const ContentGroup = styled("div")``;

export const StyledContentHeader = styled(Header)`
  margin-bottom: 16px !important;
`;
export const ContentHeader = ({ children }: IChildrenView) => <StyledContentHeader sub>{children}</StyledContentHeader>;

export const ContentKeyValuePairs = styled("div")`
  margin-bottom: 16px;
`;

export const ContentKeyValuePair = styled("div")`
  margin-right: 24px;
  display: inline-block;
`;

export const ContentKV_Key = styled("div")`
  ${objektivFont}
  color: var(--gray-500);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  &::after {
    ${yellowUnderlineBlock}
  }
`;

export const ContentKV_Value = styled("div")`
  ${objektivFont}
  height: 28px;
  color: var(--gray-950);
  font-size: 14px;
  line-height: 28px;
`;

export const StatisticsContentGroup = styled(ContentGroup)`
  margin-bottom: 0;
  h3 {
    margin-bottom: 24px;
  }
`;

export const DivWithBottomMargin = styled("div")`
  margin-bottom: 16px;
`;

export const CenteredContentActions = styled("div")`
  text-align: center;
  margin-bottom: 16px;
`;

export const OnboardingCenteredContentActions = styled(CenteredContentActions)`
  margin-top: 24px;
`;

export const CenteredContentAction = styled("div")`
  margin-left: 12px;
  margin-right: 12px;
  display: inline-block;
`;

export const CommonContentActions = styled("div")`
  ${leftMarginHeaderContent}
  margin-bottom: 16px;
`;

export const CommonContentAction = styled("div")`
  margin-right: 24px;
  display: inline-block;
`;

export const FormContentActions = styled("div")`
  margin-bottom: 16px;
`;

export const FormContentAction = styled("div")`
  margin-right: 24px;
  display: inline-block;
`;

export const FormContentActionRight = styled("div")`
  margin-left: 24px;
  display: inline-block;
`;

export const DashboardHomeHeader = styled("div")`
  ${objektivFont}
  margin-top: 30px;
  height: 50px;
  color: var(--gray-950);
  font-size: 32px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
`;

export const DashboardHomeSubHeader = styled("h3")`
  ${objektivFont}
  margin-top: 10px;
  height: 25px;
  color: var(--gray-700);
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  padding-top: 16px !important;
  padding-bottom: 32px !important;
  &.circular {
    margin-top: 40px;
  }
`;

export const CenteredContentForm = styled(Form)`
  margin: auto;
  text-align: center;
  margin-top: 48px;
`;

export const LeftAligned = styled("div")`
  text-align: left;
`;

export const StyledCardDescription = styled(Card.Description)`
  ${objektivFont}
  min-height: 29px;
  color: var(--gray-950);
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const StyledCardFootnote = styled(Card.Description)`
  ${objektivFont}
  color: var(--gray-950);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-top: 24px;
`;

export const StyledCardItemLink = styled(Card.Description)`
  ${objektivFont}
  height: 29px;
  color: var(--gray-950);
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const StyledCardHeader = styled(Card.Header)`
  ${objektivFont}
  height: 29px;
  color: var(--gray-950);
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
`;

export const StyledCardProvider = styled("div")`
  ${objektivFont}
  color: var(--gray-950);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  padding: 0 !important;
`;

export const StyledCardRegion = styled("div")`
  ${objektivFont}
  color: var(--gray-950);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 0 !important;
`;

export const StatusStyle = styled("span")`
  ${objektivFont}
  height: 18px;
  color: var(--gray-500);
  font-size: 12px;
  font-weight: bold;
  line-height: 36px;
  text-align: right;
`;

export const StatusTextOKStyle = styled("span")`
  ${objektivFont}
  height: 20px;
  color: var(--gray-950);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

export const StatusTextNOKStyle = styled("span")`
  ${objektivFont}
  height: 20px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

export const RootPassword = styled("code")`
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 8px;
`;

export const MenuItemWithLabel = styled(Menu.Item)`
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
`;

export const SideMenuLabel = styled(Label)`
  margin: 0 0 0 8px;
`;

export const EndpointContainer = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

export const ClickableStatistic = styled(Statistic)`
  cursor: pointer;
`;

export const StyledStatsSegment = styled(Segment)`
  .statistic .value span {
    font-size: inherit;
  }
  .statistic .label .icon {
    margin-left: 8px;
  }
`;

// ContentSegment renders a segment that is to be used as the content part of a page.
export interface IChildrenView {
  children?: any;
}

type IContentSegmentProps = IChildrenView & {
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
};
const StyledContentSegment = styled(Segment)`
  padding-top: ${(props: IContentSegmentProps) => props.paddingTop || "16px !important"};
  padding-left: ${(props: IContentSegmentProps) => props.paddingLeft || "24px !important"};
  padding-right: ${(props: IContentSegmentProps) => props.paddingRight || "24px !important"};
`;
export const ContentSegment = ({ children, paddingTop, paddingLeft, paddingRight }: IContentSegmentProps) => (
  <StyledContentSegment basic paddingTop={paddingTop} paddingLeft={paddingLeft} paddingRight={paddingRight}>
    {children}
  </StyledContentSegment>
);

// SecondaryMenu renders menu that is to be used in the top of the content part of a page.
const secondaryMenu = ({ children }: IChildrenView) => (
  <Menu pointing secondary>
    {children}
  </Menu>
);
export const SecondaryMenu = styled(secondaryMenu)``;

export const InlineDropdown = styled(Dropdown)`
  display: inline-block;
`;

export const UnderlinedH3 = styled("h3")`
  ${objektivFont}
  color: var(--gray-500);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  &::after {
    ${yellowUnderlineBlock}
  }
`;

export const Footnote = styled("div")`
  ${objektivFont}
  color: var(--gray-500);
  font-size: 90%;
  margin-top: -8px;
  margin-bottom: 8px;
`;

export const StyledEmptySegment = styled(Segment)`
  color: var(--gray-400);
  font-size: 1.2rem !important;
  border: 0 !important;
  box-shadow: none !important;
  padding-left: 0 !important;
`;

export const StyledEmptyTransparentSegment = styled(StyledEmptySegment)`
  color: var(--gray-400);
  background-color: transparent !important;
  padding: 10px !important;
`;

export const StyledTabWithBadge = styled.span`
  font-size: 1.1rem;
  position: relative;

  & > .badge {
    position: absolute;
  }
`;

export const StyledTransparentSegment = styled(Segment)`
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 3px !important;
`;

export const StyledLinkButton = styled(Button)`
  background: transparent !important;
  border: 0 !important;
  padding: 0 15px !important;
  color: var(--green-600) !important;
`;

export const StyledLinkDangerButton = styled(StyledLinkButton)`
  color: var(--red-700) !important;
  & > i {
    background-color: transparent !important;
    margin-righ
  }
`;

// Create an email segment
export const StyledEmailSegment = styled(Segment)`
  background-color: var(--gray-50) !important;
  border-size: 1.5px;
  border-color: var(--gray-100) !important;
  box-shadow: none !important;
  padding: 13px 0px 13px 13px !important;
  font-weight: 600 !important;
`;

export const HeaderCaption = styled.div`
  font-size: 0.9rem;
  color: var(--gray-700) !important;
  font-weight: normal !important;
`;

export const GridContainer = styled("div")`
  margin: 0em 1em;
`;

export const StyledGridHeaderRow = styled(Grid.Row)`
  padding-right: 1em !important;
`;

export const GridTitle = styled("div")`
  color: var(--gray-500);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledGridRow = styled(Grid.Row)`
  margin: 0.5em 0em;
  border: 1px solid var(--gray-300);
  border-radius: 0.5em;
  box-shadow: 0 1px 2px 0 var(--gray-300);
  font-weight: bold;
  padding-right: 1em !important;
`;

export const StyledGridColumn = styled(Grid.Column)`
  align-self: center;
  padding: 0.5em 0em 0.5em 1em !important;
}`;
