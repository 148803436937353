//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, Icon } from "@chakra-ui/react";
import React from "react";
import { InfoTooltip } from "../../components/InfoTooltip";
import { ResourceType } from "../../util/PermissionCache";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { PolicyContextProvider } from "./PolicyContextProvider";
import { PolicyListTable } from "./PolicyListTable";

export const PolicyList = ({
  resourceUrl,
  onCreatePolicyClick,
  tooltip,
  isCreatePolicyDisabled,
}: {
  resourceUrl: string | undefined;
  onCreatePolicyClick: () => void;
  tooltip: React.ReactNode;
  isCreatePolicyDisabled?: boolean;
}) => {
  const { hasPermissionByUrl } = useCheckPermissionByUrl();
  const hasPolicyUpdatePermission = hasPermissionByUrl?.(resourceUrl || "", ResourceType.Policy, "iam.policy.update");

  return (
    <PolicyContextProvider resourceUrl={resourceUrl}>
      <Grid templateRows="64px 1fr">
        <Flex alignItems={"center"} padding="4">
          <Button
            isDisabled={isCreatePolicyDisabled || !hasPolicyUpdatePermission}
            colorScheme="green"
            onClick={onCreatePolicyClick}
            rightIcon={<Icon marginLeft="16px" aria-label="Add new role binding" as={AddIcon} />}
          >
            New role binding
          </Button>
          <InfoTooltip label={tooltip} />
        </Flex>
        <PolicyListTable hasPolicyUpdatePermission={hasPolicyUpdatePermission} />
      </Grid>
    </PolicyContextProvider>
  );
};
