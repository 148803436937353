import styled from "@emotion/styled";
import { useFeature } from "flagged";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DeploymentFeatures, OrganizationList } from "../../api/lib";
import { ListDeploymentNotificationsRequest } from "../../api/notification/v1/notification";
import { Routes } from "../../routes";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { OrganizationSelector } from "../OrganizationSelector";
import { SidebarHeading } from "./SidebarHeading";
import { SidebarLink } from "./SidebarLink";
import { SidebarRightWrap } from "./SidebarRightWrap";
import { useCheckPermissionByUrl } from "./useCheckPermissionByUrl";
import { Box } from "../../ui/_box";
import { useDeploymentFeatures } from "../deployment/useDeploymentFeatures";

const useIsBackupFeatureAvailable = ({ deploymentId }: { deploymentId: string }) => {
  const idOptions = { id: deploymentId };
  const [isBackupFeatureAvailable, setIsBackupFeatureAvailable] = React.useState<boolean>(false);
  const fetchIsBackupFeatureAvailable = async () => {
    const isBackupFeatureAvailable = await apiClients.backupClient.IsBackupFeatureAvailable(idOptions);
    setIsBackupFeatureAvailable(isBackupFeatureAvailable.result || false);
  };
  React.useEffect(() => {
    fetchIsBackupFeatureAvailable();
  }, []);
  return { isBackupFeatureAvailable };
};

const useShowAuditLogLink = () => {
  const { selectedOrganization } = useDashboardContext();
  const { deployment } = useDeploymentStore();
  const { checkDeploymentPermission } = useCheckPermissionByUrl();

  const isAuditLogEnabled = useFeature("auditlog");
  const isGetAuditLogAttachmentAllowed = checkDeploymentPermission("audit.auditlogattachment.get");
  const tierHasAuditLog = !!selectedOrganization?.tier?.has_auditlog_feature;
  const isDeveloper = deployment.model?.model == "developer";
  const enableAuditLogWithList = !isDeveloper && isAuditLogEnabled && tierHasAuditLog && isGetAuditLogAttachmentAllowed;
  const enableAuditLogWithoutList = !isDeveloper && isAuditLogEnabled && !tierHasAuditLog;
  const showAuditLogLink = enableAuditLogWithList || enableAuditLogWithoutList;
  return showAuditLogLink;
};

const useNotificationCount = () => {
  const [notificationCount, setNotificationCount] = React.useState<string | undefined>();
  const { deployment } = useDeploymentStore();
  const getNotificationCount = async (deploymentId?: string) => {
    if (!deploymentId) {
      return;
    }
    const req: ListDeploymentNotificationsRequest = { deployment_id: deploymentId, unread_only: true, options: { page: 0, page_size: 10 } };
    const notifications = await apiClients.notificationClient.ListDeploymentNotifications(req);
    const { items = [] } = notifications;
    let finalCount;
    if (items.length === 0) {
      finalCount = undefined;
    } else if (items.length > 9) {
      finalCount = "9+";
    } else {
      finalCount = items.length.toString();
    }
    setNotificationCount(finalCount);
  };
  React.useEffect(() => {
    getNotificationCount(deployment.id);
  }, [deployment.id]);
  return { notificationCount };
};

const StyledIcon = styled(Icon)`
  &&& {
    height: 20px;
    width: 8px;
    margin-right: 6px;
    line-height: 22px;
  }
`;
export const DeploymentSidebar = ({
  onClickSupportRequest,
  organizations,
  onOpenInternalDashboard,
  deploymentMatch,
}: {
  onClickSupportRequest: () => void;
  organizations: OrganizationList | undefined;
  onOpenInternalDashboard: () => void;
  deploymentMatch: {
    params: {
      projectId: string;
      deploymentId: string;
    };
  };
}) => {
  return (
    <SidebarRightWrap onClickSupportRequest={onClickSupportRequest} organizationsList={organizations} onOpenInternalDashboard={onOpenInternalDashboard}>
      <SidebarLink
        to={{
          pathname: Routes.dashboard,
        }}
      >
        <StyledIcon name="angle left" />
        Back to deployments
      </SidebarLink>
      <OrganizationSelector />
      <Box display="flex" direction="column" overflow="auto">
        <SidebarHeading>Deployment</SidebarHeading>
        <SidebarLink
          to={{
            pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
            hash: "#overview",
          }}
          icon="map signs"
        >
          Overview
        </SidebarLink>
        <FullSidebar params={deploymentMatch.params} />
      </Box>
    </SidebarRightWrap>
  );
};
const FullSidebar = (deploymentMatch: {
  params: {
    projectId: string;
    deploymentId: string;
  };
}) => {
  const [features, setFeatures] = React.useState<DeploymentFeatures>({});
  const { checkDeploymentPermission } = useCheckPermissionByUrl();
  const { fetchDeploymentFeatures } = useDeploymentFeatures();
  const hasNotificationsListPermission = checkDeploymentPermission("notification.deployment-notification.list");

  const { isBackupFeatureAvailable } = useIsBackupFeatureAvailable({ deploymentId: deploymentMatch.params.deploymentId });

  const canListNotebook = checkDeploymentPermission("notebook.notebook.list");
  const canGetMLServices = checkDeploymentPermission("ml.mlservices.get");

  const showAuditLogLink = useShowAuditLogLink();

  const { notificationCount } = useNotificationCount();
  const { deployment } = useDeploymentStore();

  const getFeature = async () => {
    const { features = {}, error } = await fetchDeploymentFeatures(deployment);
    if (!error) setFeatures(features);
  };

  useEffect(() => {
    getFeature();
  }, [deployment]);
  const bootstrapped = deployment?.status?.bootstrapped;
  if (!bootstrapped) {
    return (
      <>
        <SidebarHeading>Delete/Lock</SidebarHeading>
        <SidebarLink
          to={{
            pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
            hash: "#dangerzone",
          }}
          iconColor="red"
          icon="trash"
        >
          Delete/Lock
        </SidebarLink>
      </>
    );
  }
  return (
    <>
      <SidebarHeading>Load data</SidebarHeading>

      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#dataloader",
        }}
        icon="download"
      >
        Load your data
      </SidebarLink>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#examples",
        }}
        icon="idea"
      >
        Examples
      </SidebarLink>
      <SidebarHeading>Data Science</SidebarHeading>
      <SidebarLink
        show={canListNotebook || canGetMLServices}
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#dataScience",
        }}
        icon="dna"
      >
        Data Science
      </SidebarLink>

      <SidebarHeading>Operations</SidebarHeading>

      <SidebarLink
        show={hasNotificationsListPermission}
        count={notificationCount}
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#notifications",
        }}
        icon="bell"
      >
        Notifications
      </SidebarLink>

      <SidebarLink
        show={!!features?.monitoring}
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#servers",
        }}
        icon="server"
      >
        Monitoring
      </SidebarLink>
      <SidebarLink
        show={isBackupFeatureAvailable}
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#backups",
        }}
        icon="archive"
      >
        Backups
      </SidebarLink>

      <SidebarHeading>Security</SidebarHeading>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#policy",
        }}
        icon="file alternate outline"
      >
        Policy
      </SidebarLink>
      <SidebarLink
        show={showAuditLogLink}
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#auditlog",
        }}
        icon="clipboard list"
      >
        Audit Log
      </SidebarLink>

      <SidebarHeading>Delete/Lock</SidebarHeading>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_deployment_detailsWithId(deploymentMatch.params.projectId, deploymentMatch.params.deploymentId),
          hash: "#dangerzone",
        }}
        iconColor="red"
        icon="trash"
      >
        Delete/Lock
      </SidebarLink>
    </>
  );
};
