//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../../components/InfoTooltip";
import { useFetchSupportPlans } from "./useFetchSupportPlans";

const formatResponseTime = (responseTime: number | undefined) => {
  if (!responseTime) {
    return;
  }
  if (responseTime > 60) {
    return `${responseTime / 60} hr`;
  }
  return `${responseTime} min`;
};

export const SupportPlanModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [modelField] = useField("modelId");
  const [supportPlanField] = useField("supportPlanId");
  const { data: supportPlans } = useFetchSupportPlans({
    modelId: modelField.value,
  });
  const { t } = useTranslation();
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Support Plans</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs defaultIndex={supportPlans?.items?.findIndex((supportPlan) => supportPlan.id === supportPlanField.value) || 0}>
            <TabList>
              {supportPlans?.items?.map((supportPlan) => {
                return <Tab key={supportPlan.id}>{supportPlan.name}</Tab>;
              })}
            </TabList>
            <TabPanels>
              {supportPlans?.items?.map((supportPlan) => {
                return (
                  <TabPanel key={supportPlan.id}>
                    <Flex gap="2" direction="column">
                      <Text>{supportPlan.description}</Text>
                      <Text fontSize="sm">
                        <strong>Response times</strong>
                      </Text>
                      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                        <Text fontSize="sm" display="flex" gap="2" alignItems={"center"}>
                          Critical issues
                          <InfoTooltip label={t("supportPlans.helpText.critical")} />
                        </Text>
                        <Text fontSize="sm">{formatResponseTime(supportPlan.first_response_times?.critical) || "Best effort"}</Text>
                        <Text fontSize="sm" display="flex" gap="2" alignItems={"center"}>
                          High priority issues
                          <InfoTooltip label={t("supportPlans.helpText.high")} />
                        </Text>
                        <Text fontSize="sm">{formatResponseTime(supportPlan.first_response_times?.high) || "Best effort"}</Text>
                        <Text fontSize="sm" display="flex" gap="2" alignItems={"center"}>
                          Normal priority issues
                          <InfoTooltip label={t("supportPlans.helpText.normal")} />
                        </Text>
                        <Text fontSize="sm">{formatResponseTime(supportPlan.first_response_times?.normal) || "Best effort"}</Text>
                        <Text fontSize="sm" display="flex" gap="2" alignItems={"center"}>
                          Low priority issues
                          <InfoTooltip label={t("supportPlans.helpText.low")} />
                        </Text>
                        <Text fontSize="sm">{formatResponseTime(supportPlan.first_response_times?.low) || "Best effort"}</Text>
                      </Grid>
                    </Flex>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
          <Text fontSize="xs">Support plans are the plans that define the SLA for support related to this deployment.</Text>
          <Text fontSize="xs">Note that (on request) you can change to a higher support plan, but you cannot move back to a smaller support plan.</Text>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" marginRight={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
