//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertDescription, AlertIcon, FormControl, FormLabel } from "@chakra-ui/react";
import { useFeature } from "flagged";
import { useFormikContext } from "formik";
import React from "react";
import { Region } from "../../api/lib";
import { PageSpinner } from "../../components/PageSpinner";
import SingleSelectControl from "../../components/SingleSelectControl";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchRegions } from "./useFetchRegions";
import { useReinitializeRegion } from "./useReinitializeRegion";

export const RegionSelector = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { data: regions, isFetching } = useFetchRegions({ providerId: values.providerId, modelId: values.modelId });
  useReinitializeRegion();
  const isMLServicesAvailable = useFeature("MLServices");
  const getLabel = (region: Region) => {
    const regionName = region.location || region.id || "";
    let label = regionName;
    if (region.prerelease) {
      label = `${label} (Preview)`;
    }
    if (isMLServicesAvailable && region.ml_supported) {
      label = `${label} (ML Compatible)`;
    }

    if (region.out_of_stock) {
      label = `${label} (Out of stock)`;
    } else if (region.low_stock) {
      label = `${label} (Low on stock)`;
    }

    if (!region.available) {
      label = `${label} (Not available)`;
    }

    return label;
  };

  if (!regions?.items || regions?.items.length === 0) {
    return (
      <FormControl isRequired>
        <FormLabel>Region</FormLabel>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <AlertDescription>
            No regions available for the selected provider
            {/* regions depend on model only for the Free model */}
            {values.modelId === "free" && " and Free model"}
          </AlertDescription>
          <PageSpinner isLoading={isFetching} />
        </Alert>
      </FormControl>
    );
  }

  const regionOptions = regions.items.map((region) => {
    return {
      label: getLabel(region),
      value: region.id || "",
      isDisabled: !region.available || !!region.out_of_stock,
    };
  });
  return (
    <SingleSelectControl
      label="Region"
      isRequired
      name="regionId"
      isDisabled={isDisabled}
      selectProps={{
        options: regionOptions,
        isLoading: isFetching,
        isDisabled: isFetching || isDisabled,
      }}
      tooltip="Every deployment is created in specific region of a specific cloud provider. Once a deployment has been created it is not possible to change the location anymore."
    />
  );
};
