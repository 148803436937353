//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { FreeTierID } from "../../constants";
import { Routes } from "../../routes";
import { ContentSegment } from "../../ui/lib";
import { BudgetHelper } from "../BudgetHelper";
import { HistoryHelper } from "../HistoryHelper";
import { setHasSkippedUseCaseSelection } from "../deployment/use-cases/utils";
import { IDashboardHomeProps, IDashboardHomeState } from "./DashboardHome.types";
import DeploymentListView from "./DeploymentListView";
import { FirstDeploymentMessage } from "./FirstDeploymentMessage";
import { FreeDeploymentAdView } from "./FreeDeploymentAd";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { NewDeploymentButton } from "../newDeployment/NewDeploymentButton";

// Interface describing the dashboard home view arguments
interface IDashboardHomeViewArgs extends IDashboardHomeProps, IDashboardHomeState {
  has_create_deployment_permission: boolean;
}
export const DashboardDeploymentsView = ({ ...args }: IDashboardHomeViewArgs) => {
  const projId = (args.project && args.project.id) || "";
  const { refetchDeployments } = useProjectDetailsContext();
  const onNewDeploymentCreated = (id: string) => {
    setHasSkippedUseCaseSelection(false);
    HistoryHelper.push(args.history, Routes.dashboard_project_deployment_detailsWithId(projId, id), args.topMenuInfo.getTitle());
    refetchDeployments();
  };

  const tier = args.organization.tier || {};
  const is_free_tier = tier.id === FreeTierID;
  const total_deployments_for_tier = args.organization.total_deployments?.[tier.id || ""] || 0;
  const deploymentList = args.deployments || {};
  const deploymentItems = deploymentList.items || [];
  const has_deployments = deploymentItems.length > 0;
  const projectList = args.projects || {};
  const projects = projectList.items || [];
  const has_project = !!args.project;
  const has_multiple_projects = !!args.project && projects.length > 1;
  const disable_create = !args.has_create_deployment_permission || BudgetHelper.disableDeploymentCreate(args.deployments, args.loading);
  const has_certificate = !!args.defaultCertificate;

  const first_deployment = !(disable_create || !has_project || has_multiple_projects || !has_certificate || has_deployments || total_deployments_for_tier > 0);
  const isFirstFreeDeployment = is_free_tier && first_deployment;
  return (
    <ContentSegment>
      <NewDeploymentButton />
      <FreeDeploymentAdView {...args} />
      {isFirstFreeDeployment && (
        <FirstDeploymentMessage project={args.project} defaultCertificate={args.defaultCertificate} onNewDeploymentCreated={onNewDeploymentCreated} />
      )}
      {!isFirstFreeDeployment && <DeploymentListView {...args} />}
    </ContentSegment>
  );
};
