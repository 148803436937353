//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "../home/HomeTemplate";
import { IWithSignupModalProps, withSignupModal } from "../home/SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../ui/_box";

interface IEmailNeedsVerificationViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
}

const EmailNeedsVerificationView = ({ ...args }: IEmailNeedsVerificationViewArgs) => (
  <HomeTemplateView {...args}>
    <Box maxWidth={"960px"} margin={"0 auto"}>
      <Grid columns="16" stackable>
        <GridRowIntro>
          <Grid.Column width="8">
            <a href="/">
              <img src={logo} alt="ArangoGraph" />
            </a>
            <h1 className="heading-1">Verify your email address</h1>
          </Grid.Column>
          {false && (
            <Grid.Column width="8" only="computer tablet">
              <TransparentLogo1 src={cloud} />
              <TransparentLogo2 src={cloud} />
              {/*<!-- FullLogo src={cloud} />*/}
            </Grid.Column>
          )}
        </GridRowIntro>
      </Grid>
      <div>
        <p className="para">Your email address has not been verified yet.</p>
        <p className="para">
          Please check your inbox for an email with subject <strong>Verify your email address for ArangoGraph</strong>.
        </p>
        <p className="para">
          Click on the <strong>Verify my email address</strong> link in that email. That will bring you back to ArangoGraph.
        </p>
        <p className="para">
          If you do not receive a verification email within a few minutes, please check your spam box. If you do not receive such an email, please create a
          support request using the <strong>Request help</strong> button in the top of this page.
        </p>
      </div>
    </Box>
  </HomeTemplateView>
);

interface IAuth {
  accessDeniedReason: string;
  isAuthenticated: () => boolean;
  login: () => void;
  signup: () => void;
  logout: () => void;
}

interface IEmailNeedsVerificationProps extends IWithSignupModalProps {
  auth: IAuth;
}

interface IEmailNeedsVerificationState {}

class EmailNeedsVerification extends Component<IEmailNeedsVerificationProps, IEmailNeedsVerificationState> {
  componentDidMount() {
    this.props.tracking.trackEmailNeedsVerification();
  }

  render() {
    return (
      <EmailNeedsVerificationView
        {...this.props}
        isAuthenticated={this.props.auth.isAuthenticated()}
        onClickLogin={this.props.auth.login}
        onSignup={this.props.auth.signup}
        onClickLogout={this.props.auth.logout}
      />
    );
  }
}

export default withSignupModal(EmailNeedsVerification);
