//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import {
  IDOptions as ApiIDOptions,
  Deployment as ApiDeployment,
  ListTokensRequest as ApiListTokensRequest,
  GetMetricsEndpointRequest as ApiGetMetricsEndpointRequest,
  Token as ApiToken,
} from "../../../api/lib";
import { Confirm, ConfirmInfo, ContentActionButtonNew, Processing, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../../ui/lib";
import { DeploymentPausedModal } from "../../pause/DeploymentPausedModal";
import { IWithRefreshProps, withRefresh } from "../../../util/WithRefresh";
import apiClients from "../../../api/apiclients";
import { ResourceType, Permission } from "../../../util/PermissionCache";
import CreateMetricsToken from "./CreateMetricsToken";
import { ListMetricsTokensView } from "./ListMetricsTokenViews";
import { MetricsTokenSummaryView } from "./MetricsTokenSummaryView";

interface IMetricsTokensViewArgs extends IWithRefreshProps {
  isCreateAllowed: boolean;
  showCreateMetricsToken: boolean;
  tokens: ApiToken[];
  endpoint?: string;
  loading: boolean;
  processingCreate: boolean;
  processingRevoke: boolean;
  processingDelete: boolean;
  confirmInfo?: ConfirmInfo;

  deployment: ApiDeployment;

  onResumeDeployment: () => void;
  onShowCreateMetricsToken: () => void;
  onHideCreateMetricsToken: () => void;
  onTokenCreated: (token: ApiToken) => void;
  onClickRevoke: (token: ApiToken) => void;
  onClickDelete: (token: ApiToken) => void;
}

const MetricsTokensView = ({ ...args }: IMetricsTokensViewArgs) => {
  return (
    <div>
      <Processing active={args.processingCreate} message="Creating metrics token ..." />
      <Processing active={args.processingRevoke} message="Revoking metrics token ..." />
      <Processing active={args.processingDelete} message="Deleting metrics token ..." />
      <Confirm confirmInfo={args.confirmInfo} />
      <Section>
        <SectionHead>
          <SectionHeader title="Connect with Prometheus and Grafana" />
        </SectionHead>
        <SectionContent>
          <MetricsTokenSummaryView {...args} />
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Metrics tokens" />
          <SectionButtons>
            {args.isCreateAllowed && (
              <DeploymentPausedModal
                {...args}
                is_paused={!!args.deployment.is_paused}
                onClick={args.onShowCreateMetricsToken}
                trigger={<ContentActionButtonNew primary content="New metrics token" />}
              />
            )}
            {args.showCreateMetricsToken && <CreateMetricsToken {...args} onClose={args.onHideCreateMetricsToken} />}
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          <ListMetricsTokensView {...args} />
        </SectionContent>
      </Section>
    </div>
  );
};

interface IMetricsTabProps extends IWithRefreshProps {
  deployment: ApiDeployment;
  onResumeDeployment: () => void;
}

interface IMetricsTabState {
  prevDeploymentId?: string;
  refreshNeeded: boolean;
  showCreateMetricsToken: boolean;
  processingCreate: boolean;
  processingRevoke: boolean;
  processingDelete: boolean;
  tokens?: ApiToken[];
  endpoint?: string;
  errorMessage?: string;
  confirmInfo?: ConfirmInfo;
}

class MetricsTab extends Component<IMetricsTabProps, IMetricsTabState> {
  state = {
    prevDeploymentId: undefined,
    refreshNeeded: true,
    showCreateMetricsToken: false,
    processingCreate: false,
    processingRevoke: false,
    processingDelete: false,
    tokens: undefined,
    endpoint: undefined,
    errorMessage: undefined,
  } as IMetricsTabState;

  static getDerivedStateFromProps(props: IMetricsTabProps, state: IMetricsTabState) {
    if (props.deployment.id != state.prevDeploymentId) {
      return {
        prevDeploymentId: props.deployment.id,
        refreshNeeded: true,
        tokens: undefined,
        endpoint: undefined,
      };
    }
    return {};
  }

  componentDidMount() {
    this.refreshMetricsTokens();
    this.refreshMetricsEndpoint();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshMetricsTokens();
        this.refreshMetricsEndpoint();
      });
    }
  }

  reloadMetricsTokens = async () => {
    const req = {
      deployment_id: this.props.deployment.id,
    } as ApiListTokensRequest;
    const tokens = await apiClients.metricsClient.ListTokens(req);
    this.setState({ tokens: tokens.items });
  };

  reloadMetricsEndpoint = async () => {
    const req = {
      deployment_id: this.props.deployment.id,
    } as ApiGetMetricsEndpointRequest;
    const result = await apiClients.metricsClient.GetMetricsEndpoint(req);
    this.setState({ endpoint: result.endpoint });
  };

  refreshMetricsTokens = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadMetricsTokens);
  };

  refreshMetricsEndpoint = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadMetricsEndpoint);
  };

  onShowCreateMetricsToken = () => {
    this.setState({ showCreateMetricsToken: true });
  };

  onHideCreateMetricsToken = () => {
    this.setState({ showCreateMetricsToken: false });
  };

  onTokenCreated = () => {
    this.refreshMetricsTokens();
    this.refreshMetricsEndpoint();
  };

  onClickRevoke = async (token: ApiToken) => {
    const confirmInfo = {
      header: "Revoke Metrics Token",
      content: `Are you sure you want to revoke metrics token with name '${token.name}'?`,
      invertPositiveNegative: true,
      onConfirm: async () => {
        try {
          this.setState({ processingRevoke: true, errorMessage: undefined });
          const req = {
            id: token.id,
          } as ApiIDOptions;
          await apiClients.metricsClient.RevokeToken(req);
          this.refreshMetricsTokens();
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ confirmInfo: undefined, processingRevoke: false });
        }
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDelete = async (token: ApiToken) => {
    const confirmInfo = {
      header: "Delete Metrics Token",
      content: `Are you sure you want to delete metrics token with name '${token.name}'?`,
      invertPositiveNegative: true,
      onConfirm: async () => {
        try {
          this.setState({ processingDelete: true, errorMessage: undefined });
          const req = {
            id: token.id,
          } as ApiIDOptions;
          await apiClients.metricsClient.DeleteToken(req);
          this.refreshMetricsTokens();
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ confirmInfo: undefined, processingDelete: false });
        }
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  render() {
    const hasPermission = (p: Permission) => {
      const hasPermissionByUrl = this.props.hasPermissionByUrl;
      return !!(hasPermissionByUrl && hasPermissionByUrl(this.props.deployment.url || "", ResourceType.Deployment, p));
    };
    const isCreateAllowed = hasPermission("metrics.token.create");
    const tokens = this.state.tokens || [];

    return <MetricsTokensView {...this.props} {...this.state} {...this} isCreateAllowed={isCreateAllowed} tokens={tokens} />;
  }
}

export default withRefresh()(MetricsTab);
