//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty, partition } from "lodash";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorMessage } from "../../../../ui/lib";
import { useDataloaderStore } from "../DataloaderStore";
import { FileObject } from "../types";
import { validateDroppedFiles } from "../utils";
import FileDropInteractionView from "./FileDropInteractionView";
import UploadedFileListView from "./FileListView";
import { StyledDropzoneArea, StyledErrorHolder, StyledFileUploadArea } from "./Styles";
import { v4 as getNewUUID } from "uuid";
import { useFileParsingHandlers } from "./FileParserProvider";

const FileUploadView = () => {
  const [error, setError] = useState("");
  const { updateFiles, getDeploymentDataloaderState } = useDataloaderStore();
  const { files: droppedFiles } = getDeploymentDataloaderState();
  const { queueFileToPreview } = useFileParsingHandlers();

  const onFileAddition = async (files: File[]) => {
    let fileSet: FileObject[] = droppedFiles;

    const [existingFilesWithoutSize, newFiles] = partition(files, (file) => droppedFiles.some((newFile) => newFile.name === file.name && !newFile.file.size));

    if (existingFilesWithoutSize.length) {
      fileSet = droppedFiles.map((file) => {
        const existingFile = existingFilesWithoutSize.find((f) => f.name === file.name);
        if (existingFile) {
          return {
            ...file,
            file: existingFile,
            fileSize: String(existingFile.size),
            uploadError: "",
          };
        }
        return file;
      });
    }

    const newFilesWithId: FileObject[] = newFiles.map((file) => ({
      file,
      name: file.name,
      fileSize: String(file.size),
      id: file.name + getNewUUID(),
      isBeingParsed: true,
    }));

    const validationMessage = validateDroppedFiles([...fileSet, ...newFilesWithId]);
    if (validationMessage && !isEmpty(validationMessage)) {
      const { message } = validationMessage;
      setError(message);
      return;
    }

    updateFiles([...fileSet, ...newFilesWithId]);

    queueFileToPreview(newFilesWithId).then((parsedFiles) => {
      updateFiles([...fileSet, ...parsedFiles]);
    });
  };

  const { getRootProps, getInputProps, isDragAccept, open, isDragReject, isDragActive } = useDropzone({
    noClick: true,
    accept: { "text/csv": [] },
    useFsAccessApi: true,
    minSize: 0,
    multiple: true,
    noDragEventsBubbling: false,
    onDrop: onFileAddition,
  });

  return (
    <StyledFileUploadArea>
      {error && (
        <StyledErrorHolder>
          <ErrorMessage message={error} active={!!error} header="Oops!" onDismiss={() => setError("")} />
        </StyledErrorHolder>
      )}
      <StyledDropzoneArea {...getRootProps({ isDragAccept, isDragReject, isDragActive })}>
        <input {...getInputProps()} />

        <FileDropInteractionView openFileDialog={open} hasFiles={!!droppedFiles.length} isDragActive={isDragActive} isDragReject={isDragReject} error={error} />

        {!!droppedFiles.length ? (
          <div className={`${isDragAccept || isDragActive || isDragReject ? "display-none" : ""}`}>
            <UploadedFileListView openFileDialog={open} />
          </div>
        ) : null}
      </StyledDropzoneArea>
    </StyledFileUploadArea>
  );
};

export default FileUploadView;
