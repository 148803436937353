//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { useTrackingContext } from "../../tracking/TrackingContextProvider";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { CreateDeploymentRoleBindings } from "./policy/CreateDeploymentRoleBindings";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import DeploymentDetails from "./DeploymentDetails";
import { EditDeploymentPage } from "../editDeployment/EditDeploymentPage";

export const DeploymentRoutes = (props: RouteComponentProps) => {
  const { eventSubscriptionManager, permissionCache } = useWithRefresh();
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { refetchDeployments } = useProjectDetailsContext();
  const { project } = useGlobalStore();
  const { tracking } = useTrackingContext();
  const history = useHistory();
  const onDeploymentDeleted = () => {
    history.replace(Routes.dashboard_project_deploymentsWithId(project.id || ""));
    refetchDeployments();
  };
  const onDeploymentSelected = (id: string) => {
    history.push(Routes.dashboard_project_deployment_detailsWithId(project.id || "", id));
  };
  return (
    <Switch>
      <Route path={Routes.dashboard_project_deployment_details} exact>
        <DeploymentDetails
          onDeploymentDeleted={onDeploymentDeleted}
          onDeploymentSelected={onDeploymentSelected}
          eventSubscriptionManager={eventSubscriptionManager}
          permissionCache={permissionCache}
          organization={selectedOrganization}
          {...props}
          topMenuInfo={topMenuInfo}
          tracking={tracking}
          loading={false}
          project={project}
        />
      </Route>
      <Route path={Routes.dashboard_project_deployment_edit} render={(props) => <EditDeploymentPage />} />
      <Route
        path={Routes.dashboard_project_deployment_policy}
        exact
        render={(props) => (
          <Redirect
            to={{
              pathname: Routes.dashboard_project_deployment_detailsWithId(props.match?.params.projectId || "", props.match?.params.deploymentId || ""),
              hash: "#policy",
            }}
          />
        )}
      />
      <Route path={Routes.dashboard_project_deployment_policy_create} render={() => <CreateDeploymentRoleBindings />} />
    </Switch>
  );
};
