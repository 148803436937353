//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Modal, Segment, Table } from "semantic-ui-react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  Deployment as ApiDeployment,
  CreateTestDatabaseResponse as ApiCreateTestDatabaseResponse,
  CreateTestDatabaseRequest as ApiCreateTestDatabaseRequest,
} from "../../api/lib";
import { ButtonsCardContent, Confirm, ConfirmInfo, ErrorMessage, Processing } from "../../ui/lib";
import { reportError } from "../../errors/reporting";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ITestDatabaseInfoViewArgs {
  createdTestDatabase?: string;
  copiedDatabaseInfo: boolean;
  onClose: () => void;
  onCopiedDatabaseInfo: () => void;
}

const TestDatabaseInfoView = ({ ...args }: ITestDatabaseInfoViewArgs) => {
  const data = JSON.stringify(JSON.parse(args.createdTestDatabase || "{}"), null, 2);
  return (
    <Modal size="small" open>
      <Modal.Header>Your new test database information</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="para">A new test database and user has been created for you.</p>
          <p className="para">
            Make sure to store this information in a safe place.
            <br />
            <strong>This information is displayed only here and now.</strong>
            <br />
            You cannot retrieve it afterward.
          </p>
          <Segment>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Modal.Content scrolling>
                      <SyntaxHighlighter language={"json"} wrapLines>
                        {data}
                      </SyntaxHighlighter>
                    </Modal.Content>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <CopyToClipboard text={data} onCopy={args.onCopiedDatabaseInfo}>
                      <Button icon={args.copiedDatabaseInfo ? "checkmark" : "copy"} size="tiny" />
                    </CopyToClipboard>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={args.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface ICreateTestDatabaseViewArgs {
  confirmInfo?: ConfirmInfo;
  errorMessage?: string;
  processingCreate: boolean;
  isCreateAllowed: boolean;
  handleDismissError: () => void;
  onClickCreate: () => void;
}

const CreateTestDatabaseView = ({ ...args }: ICreateTestDatabaseViewArgs) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Confirm confirmInfo={args.confirmInfo} />
          <Processing active={args.processingCreate} message="Creating test database, please wait..." />
          <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
          Create test database
        </Card.Header>
        <Card.Description>Create a test database for using ArangoDB Tutorials.</Card.Description>
      </Card.Content>
      <ButtonsCardContent extra>
        <Button attached="bottom" secondary content="Create" onClick={args.onClickCreate} disabled={!args.isCreateAllowed} />
      </ButtonsCardContent>
    </Card>
  );
};

interface ICreateTestDatabaseAPI {
  CreateTestDatabase: (req: ApiCreateTestDatabaseRequest) => Promise<ApiCreateTestDatabaseResponse>;
}

interface ICreateTestDatabaseProps extends IWithRefreshProps, RouteComponentProps {
  api: ICreateTestDatabaseAPI;
  deployment: ApiDeployment;
}

interface ICreateTestDatabaseState {
  errorMessage?: string;
  processingCreate: boolean;
  createdTestDatabase?: string;
  confirmInfo?: ConfirmInfo;
  copiedDatabaseInfo: boolean;
}

// Component to create test databases
class CreateTestDatabase extends Component<ICreateTestDatabaseProps, ICreateTestDatabaseState> {
  state = {
    errorMessage: undefined,
    processingCreate: false,
    confirmInfo: undefined,
    createdTestDatabase: undefined,
  } as ICreateTestDatabaseState;

  onClickCreate = async () => {
    this.setState({ processingCreate: true, errorMessage: undefined });
    try {
      const req = {
        deployment_id: this.props.deployment.id,
      } as ApiCreateTestDatabaseRequest;
      const resp = await this.props.api.CreateTestDatabase(req);
      const output = JSON.stringify(resp);
      this.setState({ createdTestDatabase: output });
    } catch (e) {
      this.setState({ errorMessage: `Failed to create test database: ${e}` });
      reportError(e);
    } finally {
      this.setState({ processingCreate: false });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  hasPermission = (p: Permission) => {
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(this.props.deployment.url || "", ResourceType.Deployment, p));
  };

  onCopiedDatabaseInfo = () => {
    this.setState(
      {
        copiedDatabaseInfo: true,
      },
      () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.setState({ copiedDatabaseInfo: false });
          }, 3000);
      }
    );
  };

  onClose = () => {
    this.setState({ createdTestDatabase: undefined });
  };

  render() {
    const isCreateallowed = this.hasPermission("data.deployment.create-test-database");
    return (
      <>
        <CreateTestDatabaseView
          {...this.props}
          {...this.state}
          handleDismissError={this.handleDismissError}
          onClickCreate={this.onClickCreate}
          isCreateAllowed={isCreateallowed}
        />
        {this.state.createdTestDatabase && (
          <TestDatabaseInfoView
            {...this.state}
            onCopiedDatabaseInfo={this.onCopiedDatabaseInfo}
            createdTestDatabase={this.state.createdTestDatabase}
            onClose={this.onClose}
          />
        )}
      </>
    );
  }
}

export default withRefresh()(CreateTestDatabase);
