import moment from "moment";
import React, { useEffect, useState } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from "recharts";
import { CreditBundleUsageProjection_Projection } from "../../../api/credits/v1/credits";
import { useCreditsAndUsageAPI } from "./useCreditsAndUsageAPI";
import { Box } from "../../../ui/_box";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { ErrorMessage, Loading, Section, SectionHead, SectionHeader } from "../../../ui/lib";
import { Message } from "semantic-ui-react";
import { RenderGuard } from "../../../util/RenderGuard";

const ProjectionTooltip = ({ active, payload }: { active?: boolean; payload?: Array<Payload<ValueType, NameType>> }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const { value, timestamp } = payload[0].payload || [];
  return (
    <Box backgroundColor="white" padding={"10px"} borderRadius={"4px"} boxShadow="0 0 4px 0 var(--grey-100)">
      <div>
        Estimated Remaining Credits:<b> {Number(value).toFixed(2)}</b>
      </div>
      <div>
        Date:<b> {moment(timestamp).format("MMM DD, YYYY")}</b>
      </div>
    </Box>
  );
};

export const ProjectionsGraph = ({ data = [] }: { data: CreditBundleUsageProjection_Projection[] }) => {
  if (!data || !data.length) {
    return <Message info>No Projection data available</Message>;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" tickFormatter={(timestamp) => moment(timestamp).format("MMM DD, YYYY")} />
        <YAxis />
        <Tooltip content={<ProjectionTooltip />} />
        <Legend />
        <Line dataKey="value" name="Credits Projections" fill="#7ead35" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const CreditsProjectionsGraph = () => {
  const { getCreditBundleProjectionForFutureX } = useCreditsAndUsageAPI();

  const [projections, setProjections] = useState<CreditBundleUsageProjection_Projection[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const getProjections = async () => {
    setError("");
    setLoading(true);
    const PROJECTIONS_FOR_X_MONTHS = 12;
    const { data = [], error: errorWhenGettingProjections } = await getCreditBundleProjectionForFutureX(PROJECTIONS_FOR_X_MONTHS);

    if (errorWhenGettingProjections) {
      setError("Error fetching projections");
      setLoading(false);
      return;
    }

    /*
     * Defaulting `value` to 0 as the backend doesn't provide the value object when the value is actually 0 in the backend.
     * So we get an object like {timestamp: ''} instead of {timestamp: '', value: 0} when the value is 0.
     * ref: https://arangodb.slack.com/archives/CF31Z9T3L/p1698996058812479
     */
    setProjections(data.map((item) => ({ ...item, value: item.value || 0 })));
    setLoading(false);
  };

  useEffect(() => {
    getProjections();
  }, []);

  return (
    <>
      <Section>
        <SectionHead>
          <SectionHeader
            title="Credits projection by usage"
            help="This is projected usage based on the last 30 days of usage, and the projection may change if the user’s actual usage changes"
            triggerText="What's this"
          />
        </SectionHead>
        <ErrorMessage active={!!error} message={error} />
        <RenderGuard renderIf={!!loading}>
          <Loading />
        </RenderGuard>
        <RenderGuard renderIf={!loading}>
          <Box padding={"32px 30px 0 0"}>
            <ProjectionsGraph data={projections} />
          </Box>
        </RenderGuard>
      </Section>
    </>
  );
};
export default CreditsProjectionsGraph;
