import moment, { DurationInputArg1, DurationInputArg2 } from "moment";
import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Button, Message, Popup } from "semantic-ui-react";
import { FlexBox } from "../../../ui/_flex";
import { CreditBundleUsage } from "../../../api/credits/v1/credits";
import { useCreditsAndUsageAPI } from "./useCreditsAndUsageAPI";
import { Box } from "../../../ui/_box";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useAPIUtils } from "../../../util/hooks/useAPIUtils";
import { useDataAggregator } from "../../../util/hooks/useDataAggregator";
import { RenderGuard } from "../../../util/RenderGuard";
import { Loading } from "../../../ui/_loading";
import { useDashboardContext } from "../../DashboardContextProvider";
import { Section, SectionHead, SectionHeader } from "../../../ui/_section";
import { ErrorMessage } from "../../../ui/_errorMessage";

export const CreditsGraphActions = ({
  onChange,
  disabled,
  loading,
}: {
  onChange: (start: string, end: string) => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const RANGES = {
    MONTH_1: 0,
    MONTH_3: 1,
    YEAR_1: 2,
  };

  const [usageDateRange, setUsageDateRange] = useState(RANGES.MONTH_1);

  const getDataForRange = (amount: DurationInputArg1, unit: DurationInputArg2) => {
    const startDate = moment().subtract(amount, unit).format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    onChange(startDate, endDate);
  };

  useEffect(() => {
    const start = moment().subtract(1, "month").format("YYYY-MM-DD");
    const end = moment().format("YYYY-MM-DD");
    onChange(start, end);
  }, []);

  return (
    <FlexBox justify="flex-end" padding={"32px 0"}>
      <Button.Group>
        <Popup
          position="top center"
          trigger={
            <Button
              disabled={disabled}
              loading={loading && usageDateRange === RANGES.MONTH_1}
              onClick={() => {
                getDataForRange(1, "month");
                setUsageDateRange(0);
              }}
              className={usageDateRange === RANGES.MONTH_1 ? "active" : ""}
            >
              1 month
            </Button>
          }
          content="This will show the credit usage for the last 30 days."
        ></Popup>
        <Popup
          position="top center"
          trigger={
            <Button
              disabled={disabled}
              loading={loading && usageDateRange === RANGES.MONTH_3}
              onClick={() => {
                getDataForRange(3, "month");
                setUsageDateRange(1);
              }}
              className={usageDateRange === RANGES.MONTH_3 ? "active" : ""}
            >
              3 months
            </Button>
          }
          content="This will show the credit usage for the last 90 days."
        ></Popup>
        <Popup
          position="top center"
          trigger={
            <Button
              disabled={disabled}
              loading={loading && usageDateRange === RANGES.YEAR_1}
              onClick={() => {
                getDataForRange(1, "year");
                setUsageDateRange(2);
              }}
              className={usageDateRange === RANGES.YEAR_1 ? "active" : ""}
            >
              1 year
            </Button>
          }
          content="This will show the credit usage for the last 12 months."
        ></Popup>
      </Button.Group>
    </FlexBox>
  );
};

const RateGraphTooltip = ({ active, payload }: { active?: boolean; payload?: Array<Payload<ValueType, NameType>> }) => {
  const { selectedOrganization } = useDashboardContext();

  if (!active || !payload || !payload.length) {
    return null;
  }

  const { usage, created_at } = payload[0].payload || [];
  return (
    <Box backgroundColor="white" padding={"10px"} borderRadius={"4px"} boxShadow="0 0 4px 0 var(--grey-100)">
      <div>
        Organization:<b> {selectedOrganization?.name}</b>
      </div>
      <div>
        Usage:<b> {Number(usage).toFixed(2)}</b>
      </div>
      <div>
        Date:<b> {moment(created_at).format("MMM DD, YYYY")}</b>
      </div>
    </Box>
  );
};

export const CreditUsageGraphContainer = ({ data }: { data: CreditBundleUsage[] }) => {
  if (!data.length) {
    return <Message info>No Usage data available</Message>;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)))} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" tickFormatter={(timestamp) => moment(timestamp).format("MMM DD, YYYY")} />
        <YAxis />
        <Tooltip content={<RateGraphTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="usage" name="Credits Used" stroke="var(--green-950)" activeDot={{ r: 2 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const CreditsUsageGraph = () => {
  const { getUsageDataFromRange } = useCreditsAndUsageAPI();
  const [usageData, setUsageData] = useState<CreditBundleUsage[]>([]);
  const { fetchDataForAllPages } = useAPIUtils();
  const { getAggregatedSumByDate } = useDataAggregator();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [usageCache, setUsageCache] = useState<{
    [key: string]: CreditBundleUsage[];
  }>({});

  const getUsage = async (start: string, end: string) => {
    setError(null);
    if (usageCache[start + end] && usageCache[start + end].length) {
      setUsageData(usageCache[start + end]);
      return;
    }

    setLoading(true);

    const LIMIT = 300;
    const { data = [], error } = await fetchDataForAllPages<CreditBundleUsage>((page) => getUsageDataFromRange(start, end, { page, page_size: LIMIT }), LIMIT);

    if (error) {
      setLoading(false);
      setError("Unable to fetch usage data");
      return;
    }

    const usage = getAggregatedSumByDate<CreditBundleUsage>(
      /*
       * Defaulting `usage` to 0 as the backend doesn't provide the usage object when the usage is actually 0 in the backend.
       * So we get an object like {timestamp: ''} instead of {timestamp: '', usage: 0} when the usage is 0.
       * ref: https://arangodb.slack.com/archives/CF31Z9T3L/p1698996058812479
       */
      data.map((item) => ({ ...item, usage: item.usage || 0 })),
      "created_at",
      "usage"
    );

    setUsageData(Object.values(usage));

    setUsageCache({ ...usageCache, [start + end]: Object.values(usage) });

    setLoading(false);
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Credits Usage"
          help="This shows how many credits you have been consuming per-day over your selected timeframe. This may change day-to-day based on the deployments that you have running."
          triggerText="What's this"
        />
      </SectionHead>

      <ErrorMessage active={!!error} message={error} />
      <Box position="relative">
        <CreditsGraphActions onChange={getUsage} loading={loading} disabled={loading || !usageData.length} />
        <RenderGuard renderIf={loading}>
          <Loading message="Fetching and aggregating data, please wait..." />
        </RenderGuard>
        <RenderGuard renderIf={!loading}>
          <CreditUsageGraphContainer data={usageData} />
        </RenderGuard>
      </Box>
    </Section>
  );
};
