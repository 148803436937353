//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/lib";
import { Routes } from "../../routes";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { withRefresh } from "../../util/WithRefresh";
import { BreadCrumbItem } from "../TopMenuInfo";
import { IDashboardHomeProps, IDashboardHomeState } from "./DashboardHome.types";
import { DashboardDeploymentsView } from "./DashboardDeploymentsView";

// The DashboardHome component
class DashboardHome extends Component<IDashboardHomeProps, IDashboardHomeState> {
  state = {
    projects: undefined,
    project: undefined,

    deployments: undefined,
    defaultCertificate: undefined,
    refreshNeeded: false,
  } as IDashboardHomeState;

  reloadDeploymentInfo = async () => {
    const project = this.state.project;
    if (project) {
      const listOptions = { context_id: project.id } as ApiListOptions;
      const deployments = await apiClients.dataClient.ListDeployments(listOptions);
      const certificates = await apiClients.cryptoClient.ListCACertificates(listOptions);
      const defaultCertificate = _.find(certificates.items || [], (x) => !!x.is_default);
      this.setState({ deployments: deployments, defaultCertificate: defaultCertificate });
    } else {
      this.setState({ deployments: undefined, defaultCertificate: undefined });
    }
  };

  refreshDeploymentInfo = () => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadDeploymentInfo());
  };

  static getDerivedStateFromProps(props: IDashboardHomeProps, state: IDashboardHomeState) {
    const projects = (props.projects || {}).items || [];
    const project = !_.isEmpty(projects) ? _.orderBy(projects, "created_at", "desc")[0] : undefined;
    if (!_.isEqual(props.projects, state.projects) || !_.isEqual(project, state.project)) {
      return {
        refreshNeeded: true,
        projects: props.projects,
        project: project,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    const orgURL = this.props.organization.url;
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadDeploymentInfo, `${orgURL}/Project/*`);
    this.updateTopMenu();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshDeploymentInfo);
    }
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("dashboard");
    this.props.topMenuInfo.setTitles("Deployments", "");
  };

  render() {
    const hasPermissionByUrl = this.props.hasPermissionByUrl;
    const project = this.state.project;
    const hasProjPermission = (p: Permission) => !!(hasPermissionByUrl && project && hasPermissionByUrl(project.url || "", ResourceType.Project, p));
    const has_create_deployment_permission = hasProjPermission("data.deployment.create");

    return <DashboardDeploymentsView {...this.props} {...this.state} has_create_deployment_permission={has_create_deployment_permission} />;
  }
}

export default withRefresh()(DashboardHome);
