//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { FlexBox } from "../../../ui/_flex";
import { Button, Header, Icon } from "semantic-ui-react";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { useDataloaderStore } from "../dataloader/DataloaderStore";
import { useTrackingContext } from "../../../tracking/TrackingContextProvider";
import useUploadGraphAndFiles from "../dataloader/utils/useUploadGraphAndFiles";
import { useStartImportConditions } from "../dataloader/graph-modeller/hooks/useStartImportConditions";
import { getGraphNameErrors } from "../dataloader/utils/namesValidations.utils";
import { getSize } from "../dataloader/utils";
import { parseAllFiles } from "../dataloader/hooks/useFileParser";
import DataIngestionStatusView from "../dataloader/data-ingestion/DataIngestionStatusView";
import { RenderGuard } from "../../../util/RenderGuard";
import { Box } from "../../../ui/_box";
import { StyledHeader } from "./MigrationWizard";

const DataLoaderInstructions = ({ onBack }: { onBack: () => void }) => {
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "", private_endpoint } = deployment;
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { edges, nodes, files, name, currentDatabase, migrationJob } = getDeploymentDataloaderState();
  const { db } = currentDatabase;
  const { tracking } = useTrackingContext();
  const { uploadGraphAndFiles } = useUploadGraphAndFiles();
  const { fulfilledConditions, isSaveDisabled } = useStartImportConditions();

  const isAPrivateEndpoint = !!private_endpoint;
  const hasDatabaseNotAvailable = !db || !currentDatabase.db?.name;
  const isNotImporting = migrationJob.status === "graph_modeler" || migrationJob.status === "not_started";

  const [graphNameErrors, setGraphNameErrors] = useState<string[]>(getGraphNameErrors(name));
  const hasGraphNameError = !!graphNameErrors.length;

  const goToNotStarted = () => updateDeploymentData({ migrationJob: { status: "not_started" } });
  const goToGraphModeler = () => updateDeploymentData({ migrationJob: { status: "graph_modeler" } });
  const goBack = migrationJob.status === "not_started" ? onBack : goToNotStarted;

  const handleStartImport = async () => {
    tracking.trackDataLoaderDataImportStarted({
      deploymentId,
      totalCollections: edges.length + nodes.length,
      totalDocuments: files.length,
      totalFileSize: getSize(files),
    });

    await parseAllFiles();

    const hasSomeParsedFileWithErrors = useDataloaderStore
      .getState()
      .getDeploymentDataloaderState()
      .migrationJob.filesAlreadyParsed?.some((parsedFileReport) => !!parsedFileReport.errors.length);

    if (!hasSomeParsedFileWithErrors) {
      if (!useDataloaderStore.getState().getDeploymentDataloaderState().migrationJob.migrationCancelled) {
        uploadGraphAndFiles();
      } else {
        updateDeploymentData({
          migrationJob: {
            status: "not_started",
            fileBeingParsed: undefined,
            filesAlreadyParsed: undefined,
            migrationCancelled: false,
          },
        });
      }
    }
  };

  useEffect(() => {
    setGraphNameErrors(getGraphNameErrors(name));
  }, [name]);

  if (isAPrivateEndpoint) {
    return (
      <>
        <FlexBox margin="0 0 24px 0" direction="column">
          <StyledHeader>Follow the steps below to transform your data into a graph:</StyledHeader>
        </FlexBox>
        <FlexBox
          margin="0 0 16px 0"
          justify="center"
          direction="column"
          minHeight="550px"
          backgroundColor="var(--gray-100)"
          borderRadius="12px"
          padding="22px 32px"
        >
          <FlexBox align="baseline" className="pointer" width="fit-content" margin="8px 0 0 0">
            <span onClick={onBack}>
              <Icon name="arrow left" className="dark-green-text" />
              Back
            </span>
          </FlexBox>
          <FlexBox flex={1} direction="column" margin="0 -18px" justify="center" align="center">
            <Header>ArangoGraph Data Loader to transform your data into a graph is not supported for private endpoints yet.</Header>
          </FlexBox>
        </FlexBox>
      </>
    );
  }

  return (
    <>
      <FlexBox margin="0 0 18px 0" direction="column">
        <StyledHeader>Follow the steps below to transform your data into a graph:</StyledHeader>
      </FlexBox>
      <FlexBox
        justify="center"
        direction="column"
        minHeight="550px"
        backgroundColor="var(--gray-100)"
        borderRadius="12px"
        padding="28px 32px 18px 32px"
        margin="8px 0 0 0"
      >
        <RenderGuard renderIf={isNotImporting}>
          <FlexBox align="baseline" className="pointer" width="fit-content">
            <span onClick={goBack}>
              <Icon name="arrow left" className="dark-green-text" />
              Back
            </span>
          </FlexBox>
        </RenderGuard>
        <FlexBox flex={1} direction="column" justify="space-around">
          <DataIngestionStatusView />
        </FlexBox>
        <FlexBox align="center" justify="flex-end" margin="12px 0 0 0">
          <RenderGuard renderIf={migrationJob.status === "not_started"}>
            <Button primary onClick={goToGraphModeler} disabled={hasGraphNameError || hasDatabaseNotAvailable}>
              Next
            </Button>
          </RenderGuard>
          <RenderGuard renderIf={migrationJob.status === "graph_modeler"}>
            <FlexBox align="baseline">
              <RenderGuard renderIf={!!fulfilledConditions.length}>
                <Icon name="warning circle" className="yellow" />
                <Box margin="0 16px 0 0">
                  <span>Some conditions are failing</span>
                </Box>
              </RenderGuard>

              <Button info onClick={handleStartImport} disabled={isSaveDisabled} primary>
                Save and start import
              </Button>
            </FlexBox>
          </RenderGuard>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default DataLoaderInstructions;
