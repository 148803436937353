//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Form, Grid, Modal } from "semantic-ui-react";
import {
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Processing,
  Section,
  SectionContent,
} from "../../ui/lib";

interface IEditableTitleModalProps {
  title: string;
  subTitle: string;
  onClose: () => void;
  onSave: (title: string, subTitle: string) => Promise<void>;
}

interface IEditableTitleModalState {
  title: string;
  subTitle: string;
  processing: boolean;
  errorMessage?: string;
}

class EditableTitleModal extends Component<IEditableTitleModalProps, IEditableTitleModalState> {
  state = {
    title: this.props.title,
    subTitle: this.props.subTitle,
    processing: false,
    errorMessage: undefined,
  } as IEditableTitleModalState;

  onTitleChanged = (value: string) => {
    this.setState({ title: value });
  };
  onSubTitleChanged = (value: string) => {
    this.setState({ subTitle: value });
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onSave = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      await this.props.onSave(this.state.title, this.state.subTitle);
      this.props.onClose();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };

  render() {
    return (
      <Modal open onClose={this.props.onClose}>
        <Modal.Header>Change name &amp; description</Modal.Header>
        <Modal.Content>
          <Processing active={this.state.processing} message="Saving changes, please wait..." />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.onDismissError} message={this.state.errorMessage} />
          <Form>
            <Section>
              <SectionContent>
                <Grid>
                  <Grid.Row columns={16}>
                    <Grid.Column width={4}>
                      <Form.Input
                        autoFocus
                        required
                        label="Name"
                        placeholder={`Name`}
                        name="name"
                        value={this.state.title}
                        onChange={(e, d) => this.onTitleChanged(d.value)}
                      />
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form.Input
                        label="Short description"
                        placeholder="Description"
                        name="description"
                        value={this.state.subTitle}
                        onChange={(e, d) => this.onSubTitleChanged(d.value)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SectionContent>
            </Section>
            <FormContentActions>
              <FormContentAction>
                <FormActionButtonSave primary disabled={this.state.processing} onClick={this.onSave} />
              </FormContentAction>
              <FormContentAction>
                <FormActionButtonCancel onClick={this.props.onClose} />
              </FormContentAction>
            </FormContentActions>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default EditableTitleModal;
