//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import apiClients from "../../api/apiclients";
import { Group, Role, RoleBinding, User } from "../../api/lib";
import { PageSpinner } from "../../components/PageSpinner";
import { useFetchPolicies } from "./useFetchPolicies";
import { useFetchRoleInfoList } from "./useFetchRoleInfoList";
import { usePolicyContext } from "./usePolicyContext";

const useDeleteRoleBindings = ({ roleIds }: { roleIds: (string | undefined)[] }) => {
  const { data: policies } = useFetchPolicies();
  const { resourceUrl } = usePolicyContext();
  const bindingsToDelete = policies?.bindings?.filter((binding) => binding.id && roleIds.includes(binding.id));

  return useMutation({
    mutationFn: async () => {
      await apiClients.iamClient.DeleteRoleBindings({
        resource_url: resourceUrl,
        bindings: bindingsToDelete || [],
      });
    },
  });
};

export const EditPolicyModal = ({
  isOpen,
  onClose,
  memberInfo,
  roleBindings,
}: {
  isOpen: boolean;
  onClose: () => void;
  memberInfo: Group | User | undefined;
  roleBindings: RoleBinding[];
}) => {
  const { data: roleInfoList, status } = useFetchRoleInfoList({
    roleIds: roleBindings.map((roleBinding) => roleBinding.role_id).filter((roleId) => roleId) as string[],
  });
  const { isOpen: isDeleteConfirmationOpen, onOpen: onOpenDeleteConfirmation, onClose: onCloseDeleteConfirmation } = useDisclosure();
  const [roleBindingsToDelete, setRoleBindingsToDelete] = React.useState<RoleBinding[]>([]);
  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Role bindings for member: {memberInfo?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PageSpinner isLoading={status === "loading"} />
            <CheckboxGroup
              colorScheme="green"
              onChange={(value) => {
                const roleBindingsToDelete = value
                  .map((id) => {
                    return roleBindings.find((roleBinding) => roleBinding.id === id);
                  })
                  .filter((roleBinding) => roleBinding) as RoleBinding[];
                setRoleBindingsToDelete(roleBindingsToDelete);
              }}
            >
              <Flex direction="column" gap="1">
                {roleInfoList?.map((role) => {
                  const roleBinding = roleBindings.find((roleBinding) => roleBinding.role_id === role.id);
                  return (
                    <Checkbox isDisabled={roleBinding?.delete_not_allowed} key={roleBinding?.id} value={roleBinding?.id}>
                      {role.name}
                    </Checkbox>
                  );
                })}
              </Flex>
            </CheckboxGroup>
          </ModalBody>
          <ModalFooter gap="2">
            <Button size="sm" colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            <Button
              size="sm"
              isDisabled={roleBindingsToDelete.length === 0}
              colorScheme="red"
              onClick={onOpenDeleteConfirmation}
              rightIcon={<Icon as={DeleteIcon} />}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onCloseEdit={onClose}
        onClose={onCloseDeleteConfirmation}
        roleBindingsToDelete={roleBindingsToDelete}
        memberInfo={memberInfo}
        roleInfoList={roleInfoList}
      />
    </>
  );
};

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onCloseEdit,
  roleBindingsToDelete,
  memberInfo,
  roleInfoList,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCloseEdit: () => void;
  roleBindingsToDelete: RoleBinding[];
  memberInfo: Group | User | undefined;
  roleInfoList: Role[] | undefined;
}) => {
  const { mutateAsync: onDelete, isLoading: isDeletingRoleBindings } = useDeleteRoleBindings({ roleIds: roleBindingsToDelete.map((role) => role.id) });
  const toast = useToast();
  const { refetch: refetchPolicyList, fetchStatus } = useFetchPolicies();

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton isDisabled={isDeletingRoleBindings} />
        <ModalBody>
          <Flex direction="column" gap="2">
            <Text>Are you sure you want to delete the following role bindings for member "{memberInfo?.name}"?</Text>
            <UnorderedList>
              {roleBindingsToDelete.map((roleBinding) => (
                <ListItem key={roleBinding.id}>
                  {roleInfoList?.find((role) => role.id === roleBinding.role_id)?.name} ({roleBinding.id})
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        </ModalBody>
        <ModalFooter gap="2">
          <Button isDisabled={isDeletingRoleBindings || fetchStatus === "fetching"} size="sm" colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            isDisabled={roleBindingsToDelete.length === 0}
            colorScheme="red"
            onClick={async () => {
              await onDelete();
              await refetchPolicyList();
              toast({
                title: `Role bindings: ${roleBindingsToDelete.map((roleBinding) => roleBinding.id).join(", ")} deleted`,
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              onClose();
              onCloseEdit();
            }}
            isLoading={isDeletingRoleBindings || fetchStatus === "fetching"}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
