//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ReactNode } from "react";
import { Modal, Portal } from "semantic-ui-react";
import { FormActionButtonResume } from "../../ui/lib";

interface IDeploymentPausedModalArgs {
  trigger: ReactNode;
  is_paused: boolean;
  onResumeDeployment: () => void;
  onClick?: () => void;
}

export const DeploymentPausedModal = ({ ...args }: IDeploymentPausedModalArgs) => {
  if (args.is_paused) {
    return (
      <Modal trigger={args.trigger}>
        <Modal.Header>Deployment paused</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className="para">Your deployment is currently paused.</p>
            <p className="para">Before you can continue with this action, you must first resume your deployment.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonResume primary onClick={args.onResumeDeployment} />
        </Modal.Actions>
      </Modal>
    );
  }
  return (
    <Portal trigger={args.trigger} onOpen={args.onClick}>
      <span />
    </Portal>
  );
};
