//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ReactNode } from "react";
import { Button, Modal, Portal } from "semantic-ui-react";
import { TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { TermsAndConditionsModal } from "../tandc/TermsAndConditionsModal";
import styled from "@emotion/styled";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";

const StyledDiv = styled("div")`
  margin-top: 2em !important;
`;

interface ICloneAcceptTermsAndConditionsModalArgs {
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  termsAndConditions?: ApiTermsAndConditions;
  trigger: ReactNode;
  onClick: (accept: boolean) => void;
  onCancelShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;

  is_paused: boolean;
  onResumeDeployment: () => void;

  isMultiRegionBackupEnabled?: boolean;
}

export const CloneAcceptTermsAndConditionsModal = ({ ...args }: ICloneAcceptTermsAndConditionsModalArgs) => {
  if (args.is_paused) {
    return <DeploymentPausedModal trigger={args.trigger} onResumeDeployment={args.onResumeDeployment} is_paused={args.is_paused} onClick={() => {}} />;
  }
  if (args.requiresAcceptanceOfTermsAndConditions() && args.termsAndConditions && args.termsAndConditions.id != args.acceptedTC) {
    return (
      <Modal trigger={args.trigger} open={args.showAcceptTermsAndConditions}>
        <Modal.Header>New Terms and Conditions</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className="para">
              Before you can continue you must accept our <TermsAndConditionsModal {...args} />.
            </p>
          </Modal.Description>
          <StyledDiv>
            <Button
              primary
              onClick={() => {
                args.onClick(true);
              }}
              size="small"
              content="I Accept"
              icon="check"
            />
            <Button onClick={args.onCancelShowAcceptTermsAndConditions} size="small" content="Cancel" icon="close" />
          </StyledDiv>
        </Modal.Content>
      </Modal>
    );
  }
  return (
    <Portal
      trigger={args.trigger}
      onOpen={() => {
        args.onClick(false);
      }}
    >
      <span />
    </Portal>
  );
};
