//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { SingleSelectControl } from "../../components/SingleSelectControl";
import { useFetchCACertificates } from "./useFetchCACertificates";
import CreateCACertificateModal from "../cacertificate/CreateCACertificateModal";
import { useField } from "formik";
import { CACertificate } from "../../api/lib";

export const CACertificateSelector = () => {
  const { isOpen: isCreateCACertificateModalOpen, onClose, onOpen } = useDisclosure();
  const { data: caCertificates, isLoading: isLoadingCaCertificates, refetch: refetchCACertificates } = useFetchCACertificates();

  const filteredOptions = caCertificates?.items?.map((caCertificate) => {
    let label = caCertificate.name || "";
    if (caCertificate.is_expired) {
      label = `${label} (expired)`;
    }
    if (caCertificate.will_expire_soon) {
      label = `${label} (will expire soon)`;
    }
    return {
      label,
      value: caCertificate.id || "",
    };
  });

  const [, , helpers] = useField("certificate");

  return (
    <>
      {isCreateCACertificateModalOpen && (
        <CreateCACertificateModal
          onNewCACertificateCreated={async (newCaCertificate: CACertificate) => {
            await refetchCACertificates();
            helpers.setValue(newCaCertificate.id);
            onClose();
          }}
          onClose={onClose}
        />
      )}
      <Box position="relative" flexShrink={0}>
        <Button variant="ghost" size="xs" onClick={onOpen} position="absolute" right="0" top="0" zIndex={1}>
          Create new certificate
        </Button>

        <SingleSelectControl
          label="Certificate"
          isRequired
          name="certificate"
          selectProps={{
            options: filteredOptions,
            isLoading: isLoadingCaCertificates,
            isDisabled: isLoadingCaCertificates,
          }}
          tooltip="Select or create a certificate (used in the encryption of network traffic)."
        />
      </Box>
    </>
  );
};
