//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { EditIcon } from "@chakra-ui/icons";
import { Icon, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { RoleBinding } from "../../api/lib";
import { PageSpinner } from "../../components/PageSpinner";
import { EditPolicyModal } from "./EditPolicyModal";
import { RoleCell } from "./RoleCell";
import { TableMemberCell } from "./TableMemberCell";
import { useFetchMemberInfo } from "./useFetchMemberInfo";
import { useFetchPolicies } from "./useFetchPolicies";

export const PolicyListTable = ({ hasPolicyUpdatePermission }: { hasPolicyUpdatePermission: boolean | undefined }) => {
  const { data: policies, status } = useFetchPolicies();
  if (status === "loading") {
    return <PageSpinner isLoading />;
  }
  if (!policies?.groupedBindings) {
    return <div>No policies found</div>;
  }
  return (
    <TableContainer maxHeight="100%" overflowX="auto" overflowY="auto">
      <Table>
        <Thead>
          <Tr>
            <Th width="300px">Policy Member</Th>
            <Th>Roles</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(policies?.groupedBindings).map(([member_id, roleBindings]) => (
            <Tr key={member_id}>
              <TableMemberCell memberId={member_id} />
              <RoleCell roleBindings={roleBindings} />
              <ActionCell memberId={member_id} roleBindings={roleBindings} hasPolicyUpdatePermission={hasPolicyUpdatePermission} />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ActionCell = ({
  memberId,
  roleBindings,
  hasPolicyUpdatePermission,
}: {
  memberId: string;
  roleBindings: RoleBinding[];
  hasPolicyUpdatePermission: boolean | undefined;
}) => {
  const { data: memberInfo } = useFetchMemberInfo({ memberId });
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (!hasPolicyUpdatePermission) {
    return null;
  }
  return (
    <Td>
      <IconButton colorScheme="gray" variant="ghost" size="sm" onClick={onOpen} icon={<Icon as={EditIcon} />} aria-label="Edit Policy" />

      {isOpen ? <EditPolicyModal isOpen={isOpen} onClose={onClose} memberInfo={memberInfo} roleBindings={roleBindings} /> : null}
    </Td>
  );
};
