//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import { useState, useEffect } from "react";
import apiClients from "../../api/apiclients";
import useOrganisationStore from "../../util/storage/OrganisationStore";

const useProvider = (providerId?: string) => {
  const setStoreProvider = useOrganisationStore((state) => state.setProvider);
  const storedProvider = useOrganisationStore((state) => state.getProvider(providerId || ""));

  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(storedProvider || {});
  const [error, setError] = useState(false);

  const fetchData = async (id: string) => {
    setLoading(true);
    try {
      const providerResponse = await apiClients.platformClient.GetProvider({ id });
      setStoreProvider(providerResponse);
      setProvider(providerResponse);
      setError(false);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (providerId) fetchData(providerId);
    else setLoading(false);
  }, [providerId]);

  return { loading, provider, error };
};

export default useProvider;
