//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import Markdown from "react-markdown";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Dropdown, Modal } from "semantic-ui-react";
import {
  Deployment as ApiDeployment,
  ExampleDataset as ApiExampleDataset,
  ExampleDatasetInstallation as ApiExampleDatasetInstallation,
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { ButtonsCardContent, Confirm, ConfirmInfo, ErrorMessage, Processing, StretchedButtonGroup } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import CodeBlock from "./CodeBlock";
import { ITracking } from "../../tracking/api";
import { useDeploymentPermissions } from "../deployment/useDeploymentPermissions";

const DBName = styled("code")`
  font-weight: bold;
`;

const RightFloatedDropdown = styled(Dropdown)`
  float: right;
`;

const MarkdownContainer = styled("div")`
  img {
    max-width: 100%;
  }
`;

interface IGuideViewArgs {
  open: boolean;
  example: ApiExampleDataset;
  onClose: () => void;
  endpointAvailable: boolean;
  onOpenEndpoint: () => void;
  copiedRootPassword: boolean;
  onCopyRootpassword: () => void;
}

const GuideView = ({ ...args }: IGuideViewArgs) => {
  const { isGetRootPasswordAllowed } = useDeploymentPermissions();
  return (
    <Modal open={args.open} size="large" onClose={args.onClose}>
      <Modal.Header>
        Guide
        <RightFloatedDropdown text="Deployment" button>
          <Dropdown.Menu>
            <Dropdown.Item onClick={args.onOpenEndpoint} content="Open database UI" icon="database" labelPosition="right" disabled={!args.endpointAvailable} />
            {isGetRootPasswordAllowed && (
              <Dropdown.Item
                onClick={args.onCopyRootpassword}
                content="Copy root password"
                icon={args.copiedRootPassword ? "check" : "copy"}
                labelPosition="right"
              />
            )}
          </Dropdown.Menu>
        </RightFloatedDropdown>
      </Modal.Header>
      <Modal.Content scrolling>
        <MarkdownContainer>
          <Markdown
            children={`${args.example.guide || ""}`}
            components={{
              code: CodeBlock,
              p: ({ children }) => <p className="para">{children}</p>,
              ol: ({ children }) => <ol className="ordered-list">{children}</ol>,
              ul: ({ children }) => <ul className="unordered-list">{children}</ul>,
              h1: ({ children }) => <h1 className="heading-1">{children}</h1>,
              h2: ({ children }) => <h2 className="heading-2">{children}</h2>,
              h3: ({ children }) => <h3 className="heading-3">{children}</h3>,
              h4: ({ children }) => <h4 className="heading-4">{children}</h4>,
              h5: ({ children }) => <h5 className="heading-5">{children}</h5>,
            }}
          />
        </MarkdownContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IExampleRowViewArgs {
  isInstallAllowed: boolean;
  isUninstallAllowed: boolean;
  processingInstall: boolean;
  processingUninstall: boolean;
  showGuide: boolean;
  example: ApiExampleDataset;
  confirmInfo?: ConfirmInfo;
  installation?: ApiExampleDatasetInstallation;
  errorMessage?: string;
  onClickInstall: () => void;
  onClickUninstall: (installation_id: string) => void;
  onShowGuide: () => void;
  onCloseGuide: () => void;
  handleDismissError: () => void;
  endpointAvailable: boolean;
  onOpenEndpoint: () => void;
  copiedRootPassword: boolean;
  onCopyRootpassword: () => void;
  is_paused: boolean;
  onResumeDeployment: () => void;
}

const ExampleRowStatusView = ({ ...args }: IExampleRowViewArgs) => {
  const installation = args.installation || {};
  const status = installation.status || {};
  const is_available = !!status.is_available;
  const is_failed = !!status.is_failed;
  const is_installing = !is_available && !is_failed;
  const has_database_name = !!status.database_name;

  if (!!installation.is_deleted) {
    return <div>Uninstalling... please wait</div>;
  }
  if (is_available) {
    return (
      <div>
        Installed in database <DBName>{status.database_name}</DBName>.
      </div>
    );
  }
  if (is_failed) {
    return <div>Failed to install.</div>;
  }
  if (is_installing) {
    if (has_database_name) {
      return (
        <div>
          Installing in database <DBName>{status.database_name}</DBName>... please wait.
        </div>
      );
    }
    return <div>Installing... please wait</div>;
  }
  return <div>Status unknown</div>;
};

const ExampleRowView = ({ ...args }: IExampleRowViewArgs) => {
  const can_install = !args.installation && args.isInstallAllowed;
  const can_uninstall = !!args.installation && args.isUninstallAllowed;
  const has_installation = !!args.installation;
  const installation = args.installation || {};
  const disabled = args.processingInstall;
  const installationStatus = installation.status || {};
  const isAvailable = !!installationStatus.is_available;
  const uninstalling = !!installation.deleted_at;
  const hasDemo = isAvailable && !uninstalling && !!installationStatus.demo_url;

  return (
    <Card>
      <Card.Content>
        <GuideView {...args} open={args.showGuide} onClose={args.onCloseGuide} />
        <Card.Header>
          <Confirm confirmInfo={args.confirmInfo} />
          <Processing active={args.processingInstall} message="Preparing installing example, please wait..." />
          <Processing active={args.processingUninstall} message="Preparing uninstalling example, please wait..." />
          <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
          {args.example.name}
        </Card.Header>
        <Card.Description>{args.example.description}</Card.Description>
      </Card.Content>
      {has_installation && (
        <Card.Content extra>
          <ExampleRowStatusView {...args} />
        </Card.Content>
      )}
      <ButtonsCardContent extra>
        <StretchedButtonGroup className={hasDemo ? "three" : "two"} attached="bottom">
          <Button content="Guide" onClick={args.onShowGuide} />
          {can_install && (
            <DeploymentPausedModal {...args} onClick={args.onClickInstall} trigger={<Button content="Install" secondary disabled={disabled} />} />
          )}
          {hasDemo && (
            <a href={installationStatus.demo_url || ""} target="_blank" className="ui button" rel="noreferrer">
              Demo
            </a>
          )}
          {can_uninstall && (
            <DeploymentPausedModal
              {...args}
              onClick={() => args.onClickUninstall(installation.id || "")}
              trigger={<Button content="Uninstall" disabled={disabled} />}
            />
          )}
        </StretchedButtonGroup>
      </ButtonsCardContent>
    </Card>
  );
};

interface IExampleAPI {
  CreateExampleDatasetInstallation: (req: ApiExampleDatasetInstallation) => Promise<ApiExampleDatasetInstallation>;
  DeleteExampleDatasetInstallation: (req: ApiIDOptions) => Promise<void>;
}

interface IExampleRowProps extends IWithRefreshProps, RouteComponentProps {
  api: IExampleAPI;
  example: ApiExampleDataset;
  installations: ApiExampleDatasetInstallation[];
  deployment: ApiDeployment;
  organization: ApiOrganization;
  onReloadInstallations: () => void;
  endpointAvailable: boolean;
  onOpenEndpoint: () => void;
  copiedRootPassword: boolean;
  onCopyRootpassword: () => void;
  onResumeDeployment: () => void;
  tracking: ITracking;
}

interface IExampleRowState {
  errorMessage?: string;
  processingInstall: boolean;
  processingUninstall: boolean;
  showGuide: boolean;
  confirmInfo?: ConfirmInfo;
}

// Component to list & install example datasets
class ExampleRowList extends Component<IExampleRowProps, IExampleRowState> {
  state = {
    errorMessage: undefined,
    processingInstall: false,
    processingUninstall: false,
    examples: undefined,
    showGuide: false,
    confirmInfo: undefined,
  } as IExampleRowState;

  onClickInstall = async () => {
    this.setState({ processingInstall: true, errorMessage: undefined });
    try {
      const req = {
        deployment_id: this.props.deployment.id,
        exampledataset_id: this.props.example.id,
      } as ApiExampleDatasetInstallation;
      this.props.tracking.trackExampleDatasetInstallationCreated(this.props.deployment.id, this.props.example.id);
      await this.props.api.CreateExampleDatasetInstallation(req);
      this.props.onReloadInstallations();
    } catch (e) {
      this.setState({ errorMessage: `Failed to create example dataset installation: ${e}` });
      reportError(e);
    }
    this.setState({ processingInstall: false });
  };

  onClickUninstall = (installation_id: string) => {
    const exampleName = this.props.example.name;
    const confirmInfo = {
      header: "Uninstall example",
      content: `Are you sure you want to uninstall example '${exampleName}'?`,
      warning: "This implies deletion of the database that was created for it!",
      confirm: "Uninstall!",
      invertPositiveNegative: true,
      onConfirm: () => this.onUninstallConfirmed(installation_id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;
    this.setState({ confirmInfo: confirmInfo });
  };

  onUninstallConfirmed = async (installation_id: string) => {
    this.setState({ processingUninstall: true, confirmInfo: undefined, errorMessage: undefined });
    try {
      const req = {
        id: installation_id,
      } as ApiIDOptions;
      this.props.tracking.trackExampleDatasetInstallationDeleted(this.props.deployment.id, this.props.example.id);
      await this.props.api.DeleteExampleDatasetInstallation(req);
      this.props.onReloadInstallations();
    } catch (e) {
      this.setState({ errorMessage: `Failed to remove example dataset installation: ${e}` });
      reportError(e);
    }
    this.setState({ processingUninstall: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onShowGuide = () => {
    this.setState({ showGuide: true });
  };

  onCloseGuide = () => {
    this.setState({ showGuide: false });
  };

  hasPermission = (p: Permission) => {
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(this.props.deployment.url || "", ResourceType.Deployment, p));
  };

  render() {
    const isInstallAllowed = this.hasPermission("example.exampledatasetinstallation.create");
    const isUninstallAllowed = this.hasPermission("example.exampledatasetinstallation.create");

    return (
      <ExampleRowView
        {...this.props}
        {...this.state}
        isInstallAllowed={isInstallAllowed}
        isUninstallAllowed={isUninstallAllowed}
        is_paused={!!this.props.deployment.is_paused}
        installation={_.first(this.props.installations)}
        onClickInstall={this.onClickInstall}
        onClickUninstall={this.onClickUninstall}
        onShowGuide={this.onShowGuide}
        onCloseGuide={this.onCloseGuide}
        handleDismissError={this.handleDismissError}
      />
    );
  }
}

export default withRefresh()(ExampleRowList);
