//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Button, ButtonProps } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";

export const FormButton = (props: ButtonProps) => {
  const { isSubmitting } = useFormikContext();
  return <Button {...props} type="submit" isLoading={isSubmitting} />;
};
