//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box, Flex, FormControl, FormLabel, Radio, RadioGroup, Text, Tooltip } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { PageSpinner } from "../../components/PageSpinner";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { DiskRateLimitMessage } from "./DiskRateLimitMessage";
import { useFetchDiskPerformance } from "./useFetchDiskPerformance";
import { useFetchNodeSizes } from "./useFetchNodeSizes";
import { useReinitializeDiskPerformance } from "./useReinitializeDiskPerformance";

export const DiskPerformanceRadioGroup = ({ isDisabled }: { isDisabled?: boolean }) => {
  useReinitializeDiskPerformance();
  const [regionField] = useField("regionId");
  const [modelField] = useField("modelId");
  const [nodeSizeIdField] = useField("nodeSizeId");
  const [diskPerformanceIdField, , diskPerformanceIdFieldHelpers] = useField("diskPerformanceId");
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: regionField.value,
    modelId: modelField.value,
  });

  const { data: diskPerformanceList, isRefetching } = useFetchDiskPerformance({
    regionId: regionField.value,
    modelId: modelField.value,
    nodeDiskSizeId: nodeSizeIdField.value,
    nodeDiskSize: nodeSizes?.items?.find((ns) => ns.id === nodeSizeIdField.value)?.min_disk_size || 0,
  });
  const { data: deployment } = useFetchDeploymentForEdit();
  const { disk_performance_locked: diskPerformanceLocked, disk_rate_limit_active: diskRateLimitActive } = deployment || {};
  const { checkSpecificDeploymentPermission } = useCheckPermissionByUrl();

  const hasDiskPerformanceListPermission = deployment ? checkSpecificDeploymentPermission("data.diskperformance.list", deployment) : true;

  if (!diskPerformanceList?.items?.length || diskPerformanceList?.items?.length === 1 || !hasDiskPerformanceListPermission) {
    return null;
  }

  const isDiskPerformanceChangeDisabled = isDisabled || !!diskRateLimitActive || diskPerformanceLocked || isRefetching;

  return (
    <FormControl isDisabled={isDiskPerformanceChangeDisabled}>
      <FormLabel>Disk Performance</FormLabel>
      {diskPerformanceLocked && (
        <Box bg="yellow.100" p="4" borderRadius="md" border="1px solid" borderColor="yellow.200" marginBottom="4">
          <Text>Disk performance change is currently disabled.</Text>
        </Box>
      )}
      <DiskRateLimitMessage diskRateLimitActive={diskRateLimitActive} target="Disk performance" />
      {isRefetching && <PageSpinner isLoading />}
      <RadioGroup
        onChange={(value) => {
          diskPerformanceIdFieldHelpers.setValue(value);
        }}
        value={diskPerformanceIdField.value}
        position="relative"
      >
        <Flex gap="4">
          {diskPerformanceList?.items?.map((diskPerformance) => (
            <Tooltip
              shouldWrapChildren
              key={diskPerformance.id}
              label={diskPerformance.description}
              aria-label="disk-performance-description"
              placement="auto-start"
              hasArrow
            >
              <Radio key={diskPerformance.id} value={diskPerformance.id}>
                {diskPerformance.name}
              </Radio>
            </Tooltip>
          ))}
        </Flex>
      </RadioGroup>
    </FormControl>
  );
};
