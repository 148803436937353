//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import React from "react";
import { Loader } from "semantic-ui-react";
import useRegion from "./useRegion";
import useProvider from "./useProvider";
import { ProviderIconView } from "../deployment/ProviderIcon";
import { DeploymentListLogoWithRegion } from "../../ui/_style";

interface IProviderRegion {
  regionId: string;
}

const ProviderRegion = ({ regionId }: IProviderRegion) => {
  const { loading: loadingRegion = true, region = {} } = useRegion(regionId);
  const { loading: loadingProvider = true, provider = {} } = useProvider(region.provider_id);

  const { id: providerId } = provider;

  if (loadingRegion || loadingProvider) return <Loader active inline size="tiny" data-testid="loader" />;

  return (
    <DeploymentListLogoWithRegion className="logo">
      <ProviderIconView providerId={providerId} />
      <span>{region.location || "-"}</span>
    </DeploymentListLogoWithRegion>
  );
};

export default ProviderRegion;
