//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Icon, Label } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Routes } from "../../routes";
import { TopMenuUser } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import helpUiIcon from "../../semantic/themes/arangodb-oasis-default/assets/images/icons/ui/help.svg";
import userUiIcon from "../../semantic/themes/arangodb-oasis-default/assets/images/icons/ui/user.svg";

export const StyledMenuItemInfo = styled.div`
  font-size: 0.7rem;
  padding: 20px 0 0 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #879191;
`;

export const StyledUpdateInfocon = styled(Icon)`
  position: absolute;
  top: -8px;
  right: -16px;
  font-size: 1rem !important;
`;

export const DashboardTopMenuUserDropdowns = () => {
  const { onClickLogout, onClickSupportRequest, user, pendingOrganizationInvites, onClickHelp, onClickExamples, onClickTrainingCenter, onClickStatusPage } =
    useDashboardContext();
  const location = useLocation();
  const { refreshWithTimer } = useWithRefresh();

  const [version, setVersion] = useState("");
  const existingVersion = window.VERSION;
  const getVersions = async () => {
    try {
      const version = await apiClients.dashboardClient.GetVersion({});
      const { major = 0, minor = 0, patch = 0 } = version;
      const currentVersion = `v${major}.${minor}.${patch}`;
      setVersion(currentVersion);
    } catch (err) {
      console.warn("Failed to get the version.");
    }
  };

  useEffect(() => {
    refreshWithTimer && refreshWithTimer(getVersions, 7200);
  }, []);
  return (
    <TopMenuUser>
      <Dropdown
        item
        icon={
          <span>
            <img src={helpUiIcon} alt="help" />
            {version != "" && version !== existingVersion && <StyledUpdateInfocon name="info circle" color="orange" size="small" />}
          </span>
        }
        simple
        direction="left"
        style={{ marginLeft: "24px" }}
      >
        <Dropdown.Menu>
          <Dropdown.Header>Help</Dropdown.Header>
          <Dropdown.Item as="a" onClick={onClickSupportRequest}>
            Request help
          </Dropdown.Item>
          <Dropdown.Item as="a" onClick={onClickExamples}>
            Examples
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="a" onClick={onClickHelp}>
            Documentation
          </Dropdown.Item>
          <Dropdown.Item as="a" onClick={onClickTrainingCenter}>
            Training center
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.faq}>
            Frequently asked questions
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.support}>
            Support plans
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.regions}>
            Available regions
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={Routes.terms_and_conditions}>
            Terms &amp; conditions
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.privacy_policy}>
            Privacy policy
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.data_processing_addendum}>
            Data Processing Addendum
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="a" onClick={onClickStatusPage}>
            Status
          </Dropdown.Item>
          {version != "" && <Dropdown.Divider />}
          {version != "" && version !== existingVersion && (
            <Dropdown.Item onClick={() => window.location.reload()}>
              <Icon name="refresh" />
              <label className="cursor-pointer">Refresh to get the new update</label>
              <StyledMenuItemInfo>
                <Icon name="info circle" size="small" color="orange" /> Current verion -{" "}
                <Label circular basic color="yellow" size="tiny">
                  {existingVersion}
                </Label>
                {"   "}
                New version -
                <Label circular basic color="green" size="tiny">
                  {version}
                </Label>
              </StyledMenuItemInfo>
            </Dropdown.Item>
          )}
          {version === existingVersion && (
            <Dropdown.Item className="no-pointer">
              <StyledMenuItemInfo>
                You are on the latest version -{" "}
                <Label basic circular color="green" size="tiny">
                  {version}
                </Label>
              </StyledMenuItemInfo>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        item
        icon={
          <>
            <img src={userUiIcon} alt="user" />
            {pendingOrganizationInvites ? (
              <Label color="red" circular size="mini" style={{ position: "absolute", top: "19px", left: "12px", fontSize: "0.4rem" }} />
            ) : null}
          </>
        }
        simple
        direction="left"
        style={{ marginLeft: "24px" }}
      >
        <Dropdown.Menu>
          <Dropdown.Header>Signed in as</Dropdown.Header>
          <Dropdown.Item disabled> {user ? user.name : "?"}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header>My Account</Dropdown.Header>
          <Dropdown.Item as={Link} to={Routes.dashboard_user_details} active={location.pathname === Routes.dashboard_user_details}>
            Overview
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.dashboard_user_organizations} active={location.pathname === Routes.dashboard_user_organizations}>
            My organizations
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.dashboard_user_organizationInvites} active={location.pathname === Routes.dashboard_user_organizationInvites}>
            My organization invites
            {pendingOrganizationInvites > 0 && (
              <Label color="red" circular size="tiny" style={{ marginLeft: "8px" }}>
                {pendingOrganizationInvites}
              </Label>
            )}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Routes.dashboard_user_api_keys} active={location.pathname === Routes.dashboard_user_api_keys}>
            My API keys
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={onClickLogout} style={{ marginBottom: "7px" }}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </TopMenuUser>
  );
};
