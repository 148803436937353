//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { MenuTitleText } from "../../ui/lib";
import EditableTitleModal from "./EditableTitleModal";

const EditableMenuTitleText = styled(MenuTitleText)`
  .edit-icon {
    opacity: 0.01;
    animation: edit-icon-disappear;
    animation-duration: 4s;
  }
  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
  @keyframes edit-icon-disappear {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.01;
    }
  }
`;

interface IEditableTitleProps {
  title: string;
  subTitle: string;
  save?: (title: string, subTitle: string) => Promise<void>;
}

interface IEditableTitleState {
  editing: boolean;
  title: string;
  subTitle: string;
}

// Component to create a project
class EditableTitle extends Component<IEditableTitleProps, IEditableTitleState> {
  state = {
    title: "",
    subTitle: "",
    editing: false,
  } as IEditableTitleState;

  static getDerivedStateFromProps(props: IEditableTitleProps, state: IEditableTitleState) {
    if (props.title != state.title || props.subTitle != state.subTitle) {
      return {
        title: props.title,
        subTitle: props.subTitle,
        editing: false,
      };
    }
    // No state update necessary
    return null;
  }

  onEdit = () => {
    this.setState({ editing: true });
  };
  onCloseEdit = () => {
    this.setState({ editing: false });
  };

  render() {
    const onSave = this.props.save;
    const canEdit = !!onSave && !this.state.editing;
    return (
      <EditableMenuTitleText onClick={canEdit ? this.onEdit : undefined}>
        {!!onSave && this.state.editing && <EditableTitleModal {...this.state} onClose={this.onCloseEdit} onSave={onSave} />}
        {this.state.title} {canEdit && <Icon name="pencil" size="tiny" className="edit-icon" />}
        {!_.isEmpty(this.state.subTitle) && <div className="subTitle">{this.state.subTitle}</div>}
      </EditableMenuTitleText>
    );
  }
}

export default EditableTitle;
