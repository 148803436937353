//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { extendTheme, theme as defaultTheme, TooltipProps } from "@chakra-ui/react";
import { cssVar, mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

const $tooltipBg = cssVar("tooltip-bg");

const space = {
  0.5: "2px",
  1: "4px",
  1.5: "6px",
  2: "8px",
  2.5: "10px",
  3: "12px",
  3.5: "14px",
  4: "16px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "40px",
  12: "48px",
  14: "56px",
  16: "64px",
  20: "80px",
  24: "96px",
  28: "112px",
  32: "128px",
  36: "144px",
  40: "160px",
  44: "176px",
  48: "192px",
  52: "208px",
  56: "224px",
  60: "240px",
  64: "256px",
  72: "288px",
  80: "320px",
  96: "384px",
};
export const theme = extendTheme({
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  /**
   * Spacing & sizing needs to be redefined in px, not rem, because
   * semantic.css sets HTML font-size to 14px.
   * We want to it to be 16px.
   */
  space,
  sizes: {
    ...space,
    "3xs": "224px",
    "2xs": "256px",
    xs: "320px",
    sm: "384px",
    md: "448px",
    lg: "512px",
    xl: "576px",
    "2xl": "672px",
    "3xl": "768px",
    "4xl": "896px",
    "5xl": "1024px",
    "6xl": "1152px",
    "7xl": "1280px",
    "8xl": "1440px",
    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "60px",
    "7xl": "72px",
    "8xl": "96px",
    "9xl": "128px",
  },
  colors: {
    green: {
      50: "#f5faeb",
      100: "#e9f4d3",
      200: "#d5e9ad",
      300: "#b8da7c",
      400: "#9dc853",
      500: "#7ead35",
      600: "#608726",
      700: "#4b6922",
      800: "#3e5420",
      900: "#35481f",
      950: "#1a270c",
    },
    red: {
      50: "#fff2f1",
      100: "#ffe1df",
      200: "#ffc7c4",
      300: "#ffa19b",
      400: "#ff6a61",
      500: "#ff3b30",
      600: "#e6190d",
      700: "#cc1409",
      800: "#a8150c",
      900: "#8b1811",
      950: "#4c0703",
    },
    gray: {
      50: "#f8f8f8",
      100: "#f0f0f0",
      200: "#e5e5e5",
      300: "#d1d1d1",
      400: "#b4b4b4",
      500: "#9a9a9a",
      600: "#818181",
      700: "#6a6a6a",
      800: "#5a5a5a",
      900: "#4e4e4e",
      950: "#282828",
    },
  },
  components: {
    Tabs: {
      defaultProps: {
        colorScheme: "green",
      },
      sizes: {
        sm: {
          tablist: {
            height: "38px",
          },
        },
      },
      variants: {
        line: (props: StyleFunctionProps) => {
          if (props.colorScheme === "green") {
            return {
              tab: {
                _selected: {
                  color: "black",
                  borderColor: "green.600",
                },
              },
            };
          }
          return defaultTheme.components.Tabs.variants?.line;
        },
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "green",
      },
      baseStyle: {
        control: {
          _checked: {
            bg: "green.600",
            borderColor: "green.600",
          },
        },
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "green",
      },
      baseStyle: {
        control: {
          _checked: {
            bg: "green.600",
            borderColor: "green.600",
          },
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "green",
      },
      baseStyle: {
        track: {
          _checked: {
            bg: "green.600",
          },
        },
      },
    },
    Button: {
      variants: {
        solid: (props: StyleFunctionProps) => {
          if (["green", "red"].includes(props.colorScheme)) {
            return {
              bg: `${props.colorScheme}.600`,
              color: "white",
              _hover: {
                bg: `${props.colorScheme}.700`,
              },
              _active: {
                bg: `${props.colorScheme}.700`,
              },
            };
          }
          return defaultTheme.components.Button.variants?.solid(props);
        },
      },
    },
    Table: {
      variants: {
        simple: {
          th: {
            color: "gray.700",
          },
        },
      },
    },
    Tooltip: {
      baseStyle: (props: TooltipProps) => {
        const bg = mode("gray.950", "gray.300")(props);
        return {
          [$tooltipBg.variable]: `colors.${bg}`,
        };
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          zIndex: 500,
        },
      },
    },
    Modal: {
      sizes: {
        max: {
          dialog: {
            maxW: "calc(100vw - 200px)",
            minH: "100vh",
            my: 0,
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        color: "gray.950",
      },
      "blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre, label": {
        margin: 0,
      },
      "label, input, button, select, textarea": {
        lineHeight: "inherit",
      },
      "pre, code, kbd,samp": {
        fontFamily: "SFMono-Regular,Menlo,Monaco,Consolas,monospace",
        fontSize: "1em",
      },
    },
  },
});
