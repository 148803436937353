//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, Message } from "semantic-ui-react";
import {
  CreateTestDatabaseResponse as ApiCreateTestDatabaseResponse,
  CreateTestDatabaseRequest as ApiCreateTestDatabaseRequest,
  Deployment as ApiDeployment,
  ExampleDataset as ApiExampleDataset,
  ExampleDatasetInstallation as ApiExampleDatasetInstallation,
  ExampleDatasetInstallationList as ApiExampleDatasetInstallationList,
  ExampleDatasetList as ApiExampleDatasetList,
  IDOptions as ApiIDOptions,
  ListExampleDatasetInstallationsRequest as ApiListExampleDatasetInstallationsRequest,
  ListExampleDatasetsRequest as ApiListExampleDatasetsRequest,
  Organization as ApiOrganization,
} from "../../api/lib";
import { Loading, Section, SectionContent } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import ExampleRow from "./ExampleRow";
import CreateTestDatabase from "./CreateTestDatabase";
import { ITracking } from "../../tracking/api";

const StyledInfoMessage = styled(Message)`
  margin-bottom: 48px !important;
`;

interface IExamplesViewArgs extends IWithRefreshProps, RouteComponentProps {
  api: IExampleAPI;
  createTestDatabaseApi: ICreateTestDatabaseAPI;
  examples: ApiExampleDataset[];
  installations: ApiExampleDatasetInstallation[];
  deployment: ApiDeployment;
  organization: ApiOrganization;
  onReloadInstallations: () => void;
  endpointAvailable: boolean;
  onOpenEndpoint: () => void;
  copiedRootPassword: boolean;
  onCopyRootpassword: () => void;
  onResumeDeployment: () => void;
  tracking: ITracking;
}

const ExamplesView = ({ ...args }: IExamplesViewArgs) => {
  const examples = args.examples;
  const installations = args.installations;
  return (
    <div>
      <StyledInfoMessage>
        <p className="para">These examples demonstrate some of the features of ArangoDB.</p>
        <p className="para">You can install each example in your deployment.</p>
        <p className="para">A new database will be created in your deployment to avoid mixing example data with your existing data.</p>
      </StyledInfoMessage>
      <Card.Group itemsPerRow="4">
        {examples.map((item) => (
          <ExampleRow {...args} key={item.id} example={item} installations={_.filter(installations, (x) => x.exampledataset_id == item.id)} />
        ))}
        {<CreateTestDatabase {...args} key={"create_test_database"} api={args.createTestDatabaseApi} />}
      </Card.Group>
    </div>
  );
};

interface IExampleAPI {
  CreateExampleDatasetInstallation: (req: ApiExampleDatasetInstallation) => Promise<ApiExampleDatasetInstallation>;
  DeleteExampleDatasetInstallation: (req: ApiIDOptions) => Promise<void>;
  ListExampleDatasets: (req: ApiListExampleDatasetsRequest) => Promise<ApiExampleDatasetList>;
  ListExampleDatasetInstallations(req: ApiListExampleDatasetInstallationsRequest): Promise<ApiExampleDatasetInstallationList>;
}

interface ICreateTestDatabaseAPI {
  CreateTestDatabase: (req: ApiCreateTestDatabaseRequest) => Promise<ApiCreateTestDatabaseResponse>;
}

interface IExamplesListProps extends IWithRefreshProps, RouteComponentProps {
  api: IExampleAPI;
  createTestDatabaseAPI: ICreateTestDatabaseAPI;
  deployment: ApiDeployment;
  organization: ApiOrganization;
  endpointAvailable: boolean;
  onOpenEndpoint: () => void;
  copiedRootPassword: boolean;
  onCopyRootpassword: () => void;
  onResumeDeployment: () => void;
  tracking: ITracking;
}

interface IExamplesListState {
  examples?: ApiExampleDatasetList;
  installations?: ApiExampleDatasetInstallationList;
}

// Component to list & install example datasets
class ExamplesList extends Component<IExamplesListProps, IExamplesListState> {
  state = {
    examples: undefined,
    installations: undefined,
  } as IExamplesListState;

  reloadExamples = async () => {
    const idOptions = { organization_id: this.props.organization.id } as ApiListExampleDatasetsRequest;
    const list = await this.props.api.ListExampleDatasets(idOptions);
    this.setState({ examples: list });
  };

  refreshExamples = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadExamples);
  };

  reloadInstallations = async () => {
    const idOptions = {
      deployment_id: this.props.deployment.id,
    } as ApiListExampleDatasetInstallationsRequest;
    const list = await this.props.api.ListExampleDatasetInstallations(idOptions);
    this.setState({ installations: list });
  };

  refreshInstallations = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInstallations);
  };

  componentDidMount() {
    this.refreshExamples();
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadInstallations, `${this.props.deployment.url}/ExampleDatasetInstallation/*`);
  }

  render() {
    const has_examples = !!this.state.examples;
    const has_installations = !!this.state.installations;
    const examplesList = this.state.examples || {};
    const installationsList = this.state.installations || {};

    if (has_examples && has_installations) {
      return (
        <Section>
          <SectionContent>
            <div>
              <ExamplesView
                {...this.props}
                {...this.state}
                examples={examplesList.items || []}
                installations={installationsList.items || []}
                onReloadInstallations={this.refreshInstallations}
                createTestDatabaseApi={this.props.createTestDatabaseAPI}
              />
            </div>
          </SectionContent>
        </Section>
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(ExamplesList);
