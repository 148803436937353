//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchOrganizationDeployments = ({ pageNumber = 0, pageSize = 10 }: { pageNumber: number; pageSize: number }) => {
  const { selectedOrganization } = useDashboardContext();

  const fetchOrganizationDeployments = async () => {
    const req = {
      organization_id: selectedOrganization.id,
      options: {
        page: pageNumber,
        page_size: pageSize,
      },
    };

    return await apiClients.dataClient.ListDeploymentsByFilter(req);
  };

  return useQuery(["organizationDeployments", selectedOrganization.id, pageNumber, pageSize], () => fetchOrganizationDeployments(), {
    enabled: !!selectedOrganization.id,
  });
};
