//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import Select, { Props } from "react-select";
import { getSelectBase, OptionType } from "./SelectBase";

const MultiSelectBase = getSelectBase<true>(Select);
export const MultiSelect = (props: Props<OptionType, true>) => <MultiSelectBase {...props} isMulti />;
export default MultiSelect;
