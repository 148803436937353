import React from "react";
import { Segment } from "semantic-ui-react";
import { CreditDebt } from "../../../api/credits/v1/credits";
import { Box } from "../../../ui/_box";
import { useDashboardContext } from "../../DashboardContextProvider";
import { TextLink } from "../../../ui/_textlink";

export const CreditDebtView = ({ creditDebt }: { creditDebt: CreditDebt | undefined }) => {
  const { onClickSupportRequest } = useDashboardContext();

  if (!creditDebt?.amount) return null;

  return (
    <Segment>
      <Box fontWeight={"bold"} fontSize={"2rem"} color="red">
        {creditDebt?.amount?.toFixed(2) || 0}
      </Box>
      <Box fontSize={"1rem"} fontWeight={500}>
        You have run out of credits and are currently in debt, if new credits are not purchased soon then your deployments will be automatically shut down.{" "}
        <TextLink onClick={onClickSupportRequest}>Contact us</TextLink> to discuss this further.
      </Box>
    </Segment>
  );
};
