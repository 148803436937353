import React from "react";
import { Header, Progress, Table } from "semantic-ui-react";
import { CreditBundle, CreditBundlesList } from "../../../api/credits/v1/credits";
import moment from "moment";
import { Box } from "../../../ui/_box";
import { Section, SectionHead, SectionHeader } from "../../../ui/_section";
import { RenderGuard } from "../../../util/RenderGuard";

const CreditsPurchaseTable = ({ creditBundles, hasExpiredCredits }: { creditBundles: CreditBundle[]; hasExpiredCredits?: boolean }) => {
  const getPercent = (creditsPurchased: number, creditsRemaining: number) => {
    if (creditsPurchased - creditsRemaining === 0) return 0;

    return (creditsRemaining / creditsPurchased) * 100;
  };

  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Purchased On</Table.HeaderCell>
            <Table.HeaderCell>Validity</Table.HeaderCell>
            <Table.HeaderCell>Purchased Credits</Table.HeaderCell>
            <Table.HeaderCell>Used Credits</Table.HeaderCell>
            <Table.HeaderCell>Remaining Credits</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {creditBundles.map((ccBundle) => {
            return (
              <Table.Row key={ccBundle.id}>
                <Table.Cell>
                  <Header as="h3">
                    ArangoGraph Credits
                    <Header as="h5" disabled>
                      <Box padding={"8px 0"}>{ccBundle.id}</Box>
                    </Header>
                  </Header>
                </Table.Cell>
                <Table.Cell>{moment(ccBundle.purchased_at).format("MMM DD, YYYY")}</Table.Cell>
                <Table.Cell>
                  {moment(ccBundle.valid_from).format("MMM DD, YYYY")} - {moment(ccBundle.valid_until).format("MMM DD, YYYY")}
                </Table.Cell>
                <Table.Cell>{Number(ccBundle.credits_purchased).toFixed(2)}</Table.Cell>
                <Table.Cell>{(Number(ccBundle.credits_purchased) - Number(ccBundle.credits_remaining)).toFixed(2)}</Table.Cell>
                <Table.Cell>{Number(ccBundle.credits_remaining).toFixed(2)}</Table.Cell>
                <RenderGuard renderIf={!!hasExpiredCredits}>
                  <Table.Cell>Credits expired</Table.Cell>
                </RenderGuard>
                <RenderGuard renderIf={!hasExpiredCredits}>
                  <Table.Cell>
                    {Number(ccBundle.credits_purchased) - Number(ccBundle.credits_remaining) > 0 ? (
                      <Progress percent={getPercent(Number(ccBundle.credits_purchased), Number(ccBundle.credits_remaining))} size="tiny" success />
                    ) : (
                      <Box>Fully available</Box>
                    )}
                  </Table.Cell>
                </RenderGuard>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export const ActiveCreditsBundlePurchaseView = ({ creditBundles }: { creditBundles: CreditBundlesList }) => {
  const { items = [] } = creditBundles;

  const activeCreditBundles = items.filter((item) => {
    return moment(item.valid_until).isAfter(moment()) && Number(item.credits_remaining) > 0;
  });

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Available Credits & History" />
      </SectionHead>
      <CreditsPurchaseTable creditBundles={activeCreditBundles} />
    </Section>
  );
};

export const ExpiredCreditsBundlePurchaseHistory = ({ creditBundles }: { creditBundles: CreditBundlesList }) => {
  const { items = [] } = creditBundles;

  const expiredCreditBundle = items.filter((item) => {
    return moment(item.valid_until).isBefore(moment()) || Number(item.credits_remaining) === 0;
  });

  if (!expiredCreditBundle.length) return null;

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Credit Purchase History" />
      </SectionHead>
      <Box opacity={0.7} padding={"16px 0"}>
        <CreditsPurchaseTable creditBundles={expiredCreditBundle} hasExpiredCredits />
      </Box>
    </Section>
  );
};
