//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFeature } from "flagged";
import { find } from "lodash";
import React from "react";
import { HashRouter, Redirect, RouteComponentProps, Switch, useLocation } from "react-router-dom";
import apiClients from "../../api/apiclients";
import {
  BackupList as ApiBackupList,
  BackupPolicyList as ApiBackupPolicyList,
  CPUSizeList as ApiCPUSizeList,
  Deployment as ApiDeployment,
  Deployment_CertificateSpec as ApiDeployment_CertificateSpec,
  NodeSizeList as ApiNodeSizeList,
  Organization as ApiOrganization,
  PrepaidDeployment as ApiPrepaidDeployment,
  Project as ApiProject,
  Plan as ApiSupportPlan,
  TermsAndConditions as ApiTermsAndConditions,
  DeploymentReplication,
  DeploymentMigration,
} from "../../api/lib";
import { PrivateEndpointService as ApiPrivateEndpointService } from "../../api/network/v1/network";
import { ITracking } from "../../tracking/api";
import { Section } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import { DeploymentAuditLogTabWrap } from "../auditlog/DeploymentAuditLogTabWrap";
import ExamplesList from "../example/ExamplesList";
import MetricsTab from "../monitoring/metrics-token/MetricsTab";
import NotificationView from "../notifications/NotificationView";
import { AWSPrincipal, RegionProviders } from "../private-network/types";
import { BackupsView, ICreateBackupViewArgs, IEditBackupViewArgs } from "./BackupViews";
import { DangerZoneView } from "./DangerZoneViews";
import OverviewTabContent from "./OverviewTabContent";
import { IPasswordDeploymentPageArgs } from "./PasswordSettingsModalView";
import { ServersStatusView } from "./ServersViews";
import DataScienceView from "./data-science/DataScienceView";
import { useDashboardContext } from "../DashboardContextProvider";
import { BootstrappingModal } from "./BootstrappingModal";
import { Routes } from "../../routes";
import { useDeploymentPermissions } from "./useDeploymentPermissions";
import MetricsView from "../monitoring/metrics/MetricsView";
import MigrationWizard from "./migration-wizard/MigrationWizard";
import { Message } from "semantic-ui-react";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { NotificationBar } from "../../components/NotificationBar";
import { DeploymentPolicyList } from "./policy/DeploymentPolicyList";

interface IDeploymentDetailsViewArgs extends IWithRefreshProps, RouteComponentProps, ICreateBackupViewArgs, IEditBackupViewArgs, IPasswordDeploymentPageArgs {
  topMenuInfo: TopMenuInfo;
  processing: boolean;
  activeTabIndex: number;
  onTabChange: (index: number) => void;
  deployment: ApiDeployment;
  prepaidDeployment?: ApiPrepaidDeployment;
  organization: ApiOrganization;
  project: ApiProject;
  supportPlans: ApiSupportPlan[];
  certificates?: ApiDeployment_CertificateSpec;
  node_sizes: ApiNodeSizeList;
  cpu_sizes: ApiCPUSizeList;
  tierHasSupportPlans: boolean;
  rootPasswordCopied: boolean;
  loadingRootPassword: boolean;
  rootPassword?: string;
  onCopyRootPassword: () => void;
  showRootPassword: boolean;
  onShowRootPasswordToggle: () => void;
  onOpenEndpoint: () => void;
  onResumeDeployment: () => void;
  onNewRoleBinding: () => void;
  onDeleteDeployment: () => void;
  isBackupFeatureAvailable: boolean;
  backups?: ApiBackupList;
  lastGoodBackups?: ApiBackupList;
  backupPolicies?: ApiBackupPolicyList;
  onClickCreateBackup: () => void;
  onClickBackupRestore: (id: string) => void;
  onClickBackupEdit: (id: string) => void;
  onClickBackupDownload: (id: string) => void;
  onClickBackupDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string, needsNewTC: boolean) => void;
  onEditDeployment: () => void;
  onUpdatePrepaidDeployment: () => void;
  onLockDeployment: () => void;
  onUnlockDeployment: () => void;
  onPauseDeployment: () => void;
  isDiskAlmostFull: boolean | undefined;

  backupPage: number;
  backupPageSize: number;
  onNextBackupPage: () => void;
  onPreviousBackupPage: () => void;

  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  onClickCancelShowAcceptTermsAndConditions: () => void;
  onClickTriggerShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;

  expandServers: boolean;
  onToggleExpandServers: () => void;

  onRotateServer: (serverID: string) => void;
  tierHasAuditLogFeature: boolean;

  notificatonCount: string | number | undefined;

  isPrivateNetworkAvailable: boolean;
  privateNetworkDetails: ApiPrivateEndpointService | undefined;

  handleConversionToPrivateNetwork: () => void;
  onSubscriptionIDChange: (subscriptionID: string[]) => Promise<{ error: unknown } | undefined>;
  onAlternateDNSNamesChange: (data: { alternateDNSNames: string[]; enablePrivateDNS?: boolean }) => Promise<{ error: unknown } | undefined>;
  onAWSPrincipalsChange: (awsPrincipals: AWSPrincipal[]) => Promise<{ error: unknown } | undefined>;
  onGCPProjectNameChange: (projectNames: string[]) => Promise<{ error: unknown } | undefined>;

  provider: RegionProviders;

  replicationDetails?: DeploymentReplication;

  isMultiRegionBackupEnabled?: boolean;

  isPauseFeatureAvailable: boolean | undefined;
  isMonitoringFeatureAvailable: boolean;
  tracking: ITracking;

  migrationStatus?: DeploymentMigration;
}

export const DeploymentDetailsView = ({ ...args }: IDeploymentDetailsViewArgs) => {
  const bootstrapped = args.deployment.status && args.deployment.status.bootstrapped;
  const { deployment } = useDeploymentStore();
  const { notifications } = deployment;

  return (
    <>
      <NotificationBar notifications={notifications} marginBottom="5" borderRadius="md" />
      <HashRouter basename="/" hashType="noslash">
        <Switch>
          <Redirect path="/" exact to="/overview" />
        </Switch>
      </HashRouter>
      {!bootstrapped ? <BootstrappingRoutes {...args} /> : <RenderRoutes {...args} />}
    </>
  );
};

const BootstrappingRoutes = (props: IDeploymentDetailsViewArgs) => {
  const location = useLocation();

  if (location.hash === "#dangerzone") {
    return (
      <DangerZoneView
        {...props}
        locked={!!props.deployment.locked}
        processing={props.processing}
        isDeploymentPaused={!!props.deployment.is_paused}
        isPauseFeatureAvailable={props.isPauseFeatureAvailable}
      />
    );
  }
  return <BootstrappingModal deployment={props.deployment} />;
};
const RenderRoutes = (props: IDeploymentDetailsViewArgs) => {
  const { isBackupFeatureAvailable, deployment, tierHasAuditLogFeature, isMonitoringFeatureAvailable } = props;
  const { isListMetricsTokensAllowed, isGetAuditLogAttachmentAllowed } = useDeploymentPermissions();

  const isAuditLogEnabled = useFeature("auditlog");
  const model = deployment.model || {};
  const status = deployment.status || {};
  const node_size = find(props.node_sizes.items || [], (x) => x.id == model.node_size_id);
  const cpu_size = find(props.cpu_sizes.items || [], (x) => x.id == (node_size || {}).cpu_size);
  const endpointAvailable = !!status.endpoint && !!status.bootstrapped;
  const isDeveloper = model.model == "developer";
  const enableAuditLogWithList = !isDeveloper && isAuditLogEnabled && tierHasAuditLogFeature && isGetAuditLogAttachmentAllowed;
  const enableAuditLogWithoutList = !isDeveloper && isAuditLogEnabled && !tierHasAuditLogFeature;
  const { onClickSupportRequest } = useDashboardContext();
  const location = useLocation();
  const projectId = props.project.id || "";
  const deploymentId = props.deployment.id || "";
  switch (location.hash) {
    case "#dataloader":
      return <MigrationWizard />;
    case "#notifications":
      return <NotificationView {...props} onClickSupportRequest={onClickSupportRequest} />;
    case "#examples":
      return (
        <ExamplesList
          {...props}
          api={apiClients.exampleClient}
          createTestDatabaseAPI={apiClients.dataClient}
          endpointAvailable={endpointAvailable}
          copiedRootPassword={props.rootPasswordCopied}
          onCopyRootpassword={props.onCopyRootPassword}
        />
      );
    case "#servers":
      if (!isMonitoringFeatureAvailable) {
        return <Redirect to={Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId)} />;
      }
      return (
        <Section>
          <ServersStatusView
            {...props}
            status={props.deployment.status}
            model={props.deployment.model}
            node_size={node_size}
            cpu_size={cpu_size}
            servers={props.deployment.servers}
            isDeploymentPaused={props.deployment.is_paused || false}
            version={props.deployment.version}
            custom_image={props.deployment.custom_image}
            deploymentId={props.deployment.id || ""}
            expanded={props.expandServers}
            onToggleExpand={props.onToggleExpandServers}
            hasRoles={!isDeveloper}
          />
          <MetricsView />
          {isListMetricsTokensAllowed && <MetricsTab {...props} />}
        </Section>
      );
    case "#backups":
      if (!isBackupFeatureAvailable) {
        return <Redirect to={Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId)} />;
      }
      return (
        <BackupsView
          {...props}
          page={props.backupPage}
          pageSize={props.backupPageSize}
          onNextPage={props.onNextBackupPage}
          onPreviousPage={props.onPreviousBackupPage}
          onClickCancelShowAcceptTermsAndConditions={props.onClickCancelShowAcceptTermsAndConditions}
          onClickTriggerShowAcceptTermsAndConditions={props.onClickTriggerShowAcceptTermsAndConditions}
          requiresAcceptanceOfTermsAndConditions={props.requiresAcceptanceOfTermsAndConditions}
        />
      );
    case "#dataScience":
      return <DataScienceView />;
    case "#auditlog":
      if (!enableAuditLogWithList && !enableAuditLogWithoutList) {
        return <Redirect to={Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId)} />;
      }
      return <DeploymentAuditLogTabWrap enableAuditLogWithList={enableAuditLogWithList} enableAuditLogWithoutList={enableAuditLogWithoutList} {...props} />;
    case "#policy":
      return <DeploymentPolicyList />;
    case "#dangerzone":
      const upgradeInProgress =
        !!props.migrationStatus?.status?.phase &&
        props.migrationStatus.status.phase.toLowerCase() !== "failed" &&
        props.migrationStatus.status.phase.toLowerCase() !== "error";
      return (
        <>
          {upgradeInProgress && (
            <Message negative>
              This free deployment is being upgraded to a paid one and will be automatically deleted after the upgrade. As a result, no action can be performed
              during the upgrade.
            </Message>
          )}
          <DangerZoneView
            {...props}
            disableAllActions={upgradeInProgress}
            locked={!!props.deployment.locked}
            processing={props.processing}
            isDeploymentPaused={!!props.deployment.is_paused}
            isPauseFeatureAvailable={props.isPauseFeatureAvailable}
          />
        </>
      );
    case "#overview":
    default:
      return (
        <OverviewTabContent
          {...props}
          migrationStatus={props.migrationStatus}
          nodeSizes={props.node_sizes}
          showRootPassword={props.showRootPassword}
          loadingRootPassword={props.loadingRootPassword}
          prepaidDeployment={props.prepaidDeployment || undefined}
          cpuSizes={props.cpu_sizes}
          deployment={props.deployment}
          processing={props.processing}
          rootPasswordCopied={props.rootPasswordCopied}
          isDiskAlmostFull={props.isDiskAlmostFull}
          tierHasSupportPlans={props.tierHasSupportPlans}
          onShowRootPasswordToggle={props.onShowRootPasswordToggle}
          onCopyRootPassword={props.onCopyRootPassword}
          onResumeDeployment={props.onResumeDeployment}
          onEditDeployment={props.onEditDeployment}
          onUpdatePrepaidDeployment={props.onUpdatePrepaidDeployment}
          onOpenEndpoint={props.onOpenEndpoint}
          supportPlans={props.supportPlans}
          provider={props.provider}
          replicationDetails={props.replicationDetails}
          onClickSupportRequest={onClickSupportRequest}
        />
      );
  }
};
