import { useEffect } from "react";
import { Timestamp } from "./api/googleTypes";
import { Organization } from "./api/lib";
import { useFetchCurrentUser } from "./useFetchCurrentUser";

const toUnix = (timestamp: Timestamp) => {
  return Math.floor(new Date(timestamp).getTime() || 0 / 1000);
};
export const useSetupAppcues = ({ selectedOrganization }: { selectedOrganization: Organization | undefined }) => {
  const { data: currentUser } = useFetchCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    window.Appcues.identify(currentUser.id, {
      email: currentUser.email,
      firstName: currentUser.given_name,
      lastName: currentUser.family_name,
      name: currentUser.name,
      createdAt: toUnix(currentUser.created_at!),
      companyName: currentUser.company_name,
    });

    if (!selectedOrganization) {
      return;
    }

    window.Appcues.group(selectedOrganization.id, {
      createdAt: toUnix(selectedOrganization.created_at!),
      planTier: selectedOrganization.tier?.id,
      companyName: selectedOrganization.name,
    });
  }, [currentUser, selectedOrganization]);
};
