//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Grid, InputOnChangeData } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, Project as ApiProject, ProjectList } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import RedirectToBillingPopover from "../pricing/RedirectToBillingPopover";
import { BudgetHelper } from "../BudgetHelper";
interface ICreateProjectViewArgs extends ICreateProjectState, ICreateProjectProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  createProject: () => void;
  projects?: ProjectList;
}
const CreateProjectView = ({ ...args }: ICreateProjectViewArgs) => {
  const { organization, projects } = args;
  const { tier = {}, id: organizationId } = organization;

  const hasReachedFreeTierLimit = tier.id === "free" && BudgetHelper.disableProjectCreate(projects, args.processing);

  return (
    <ContentSegment>
      <Processing active={args.processing} message="Creating project, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <MainContent>
        <Form>
          <Section>
            <SectionHeader title="General" />
            <SectionContent>
              <Grid>
                <Grid.Row columns={16}>
                  <Grid.Column width={4}>
                    <Form.Input
                      autoFocus
                      required
                      label="Name"
                      placeholder='Name (e.g. "My team name" or "Development")'
                      name="name"
                      value={args.name}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Form.Input
                      label="Short description"
                      placeholder="Description"
                      name="description"
                      value={args.description}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SectionContent>
          </Section>
          <FormContentActions>
            <FormContentAction>
              <RedirectToBillingPopover organizationId={organizationId || ""} disabled={!hasReachedFreeTierLimit}>
                <FormActionButtonCreate primary disabled={!args.active || !args.name} onClick={args.createProject} />
              </RedirectToBillingPopover>
            </FormContentAction>
            <FormContentAction>
              <FormActionButtonCancel onClick={args.history.goBack} />
            </FormContentAction>
          </FormContentActions>
        </Form>
      </MainContent>
    </ContentSegment>
  );
};

// Interface decribing the properties of the CreateProject component
interface ICreateProjectProps extends RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onNewProjectCreated: (projectId: string, fromDashboardHome: boolean) => void;
}

// Interface decribing the state of the CreateProject component
interface ICreateProjectState {
  errorMessage?: string;
  processing: boolean;
  name: string;
  description: string;
}

// Component to create a project
class CreateProject extends Component<ICreateProjectProps, ICreateProjectState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
  } as ICreateProjectState;

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  createProject = async () => {
    if (!this.state.name) {
      this.setState({ errorMessage: "Project name cannot be empty" });
      return;
    }

    try {
      this.setState({ processing: true, errorMessage: undefined });
      const project = {
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
      } as ApiProject;
      const createdProject = await apiClients.resourceManagerClient.CreateProject(project);

      this.props.onNewProjectCreated(createdProject.id || "", HistoryHelper.isFrom(this.props.history, Routes.dashboard));
    } catch (e) {
      this.setState({ errorMessage: `Project creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("project");
    this.props.topMenuInfo.setTitles(this.state.name || "New project", "");
  };

  render() {
    return (
      <CreateProjectView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        createProject={this.createProject}
      />
    );
  }
}

export default CreateProject;
