//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormControl, FormControlProps, FormLabel, Grid, GridProps } from "@chakra-ui/react";
import React from "react";
import { InfoTooltip } from "./InfoTooltip";

export interface LabeledFormControlProps extends FormControlProps {
  label: string;
  htmlFor?: string;
  tooltip?: string | React.ReactNode;
  gridWrapperProps?: GridProps;
}

const LabeledFormControl = ({ label, htmlFor, children, tooltip, gridWrapperProps, ...rest }: LabeledFormControlProps) => {
  return (
    <FormControl {...rest}>
      <Grid marginBottom="2" gridTemplateColumns="min-content 40px" alignItems="center" {...gridWrapperProps}>
        <FormLabel margin="0" htmlFor={htmlFor} whiteSpace="nowrap">
          {label}
        </FormLabel>
        {tooltip && (
          <InfoTooltip
            label={tooltip}
            boxProps={{
              paddingX: "2",
              paddingY: "1",
            }}
          />
        )}
      </Grid>
      {children}
    </FormControl>
  );
};

export default LabeledFormControl;
