//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import React from "react";
import { useDashboardContext } from "../DashboardContextProvider";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import SingleSelectControl from "../../components/SingleSelectControl";
import { useFetchDeploymentFeatures } from "./useFetchDeploymentFeatures";
import { useFetchIAMProviders } from "./useFetchIAMProviders";

export const IAMProviderSelector = () => {
  const { data: iamProviders } = useFetchIAMProviders();
  const { selectedOrganization } = useDashboardContext();
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { modelId, regionId, nodeSizeId } = values;
  const { data: features } = useFetchDeploymentFeatures({
    modelId,
    regionId,
    nodeSizeId,
  });
  const isAllowedToUseIAMProviders = !!selectedOrganization.is_allowed_to_use_iamproviders;
  const isIAMProviderFeatureEnabled = !!features?.iamprovider;
  if (!isAllowedToUseIAMProviders || !isIAMProviderFeatureEnabled) {
    return null;
  }

  return (
    <SingleSelectControl
      label="IAM Provider"
      isRequired
      name="iamProviderId"
      selectProps={{
        options: iamProviders?.items?.map((iamProvider) => ({
          label: iamProvider.name || "",
          value: iamProvider.id || "",
        })),
      }}
    />
  );
};
