//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { BudgetHelper } from "../BudgetHelper";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { NewDeploymentProjectSelectorModal } from "./NewDeploymentProjectSelectorModal";
import { useFetchOrganizationDeployments } from "./useFetchOrganizationDeployments";

export const NewDeploymentButton = () => {
  const { checkSpecificProjectPermission } = useCheckPermissionByUrl();
  const history = useHistory();
  const { projects, isFreeTier } = useDashboardContext();
  const { data: organizationDeployments, isLoading: isOrganizationDeploymentsLoading } = useFetchOrganizationDeployments({
    pageNumber: 0,
    pageSize: 10,
  });
  const { isOpen: isNewDeploymentModalOpen, onOpen: onOpenNewDeploymentModal, onClose: onCloseNewDeploymentModal } = useDisclosure();

  // doesn't have any deployments & has only one project
  const isFirstDeployment = !organizationDeployments?.items?.length && projects?.items?.length === 1;

  // if the user is on the free tier and it's the first deployment, we should not show the button
  if (isFreeTier && isFirstDeployment) {
    return null;
  }

  // BudgetHelper treats 'no deployments' as budget limit reached, which is incorrect. So we need a special case here
  const hasReachedBudgetLimit = organizationDeployments ? BudgetHelper.disableDeploymentCreate(organizationDeployments) : false;

  const hasCreateDeploymentPermissionForFirstProject = checkSpecificProjectPermission("data.deployment.create", projects?.items?.[0] || {});

  // if there is only one project and the user doesn't have permission to create a deployment, we should disable the button
  const isDisabledForSingleProject = projects?.items?.length === 1 && !hasCreateDeploymentPermissionForFirstProject;

  const isPopoverActive = hasReachedBudgetLimit || !projects?.items?.length || isDisabledForSingleProject;

  const isDisabled = isOrganizationDeploymentsLoading || hasReachedBudgetLimit || !projects?.items?.length || isDisabledForSingleProject;
  return (
    <>
      <Popover
        trigger="hover"
        offset={[64, 8]}
        // this ensures the if the button is disabled, the popover will work
        isOpen={isPopoverActive ? undefined : false}
      >
        <PopoverTrigger>
          <Flex display="inline-flex" marginBottom="4">
            <Button
              colorScheme="green"
              size="md"
              onClick={() => {
                if (projects?.items?.length === 1) {
                  history.push(Routes.dashboard_project_deployments_createWithId(projects?.items?.[0]?.id || ""));
                  return;
                }
                onOpenNewDeploymentModal();
              }}
              rightIcon={<Icon marginLeft="16px" aria-label="Add new deployment" as={AddIcon} />}
              isLoading={isOrganizationDeploymentsLoading}
              isDisabled={isDisabled}
            >
              New Deployment
            </Button>
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          _focus={{
            outline: "none",
          }}
        >
          <PopoverArrow />
          <PopoverBody>
            <ButtonPopoverBody isDisabledForSingleProject={isDisabledForSingleProject} hasReachedBudgetLimit={hasReachedBudgetLimit} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <NewDeploymentProjectSelectorModal isOpen={isNewDeploymentModalOpen} onClose={onCloseNewDeploymentModal} />
    </>
  );
};

const ButtonPopoverBody = ({ hasReachedBudgetLimit, isDisabledForSingleProject }: { hasReachedBudgetLimit: boolean; isDisabledForSingleProject: boolean }) => {
  const { isFreeTier, projects, selectedOrganization } = useDashboardContext();
  const history = useHistory();
  const hasNoProjects = !projects?.items?.length;
  if (hasNoProjects) {
    return (
      <Flex direction="column" gap="4">
        <Text>To create a new deployment, you must have an existing project.</Text>
        <Button
          colorScheme="green"
          onClick={() => {
            history.push(Routes.dashboard_organization_projects_createWithId(selectedOrganization?.id || ""));
          }}
        >
          Create new project
        </Button>
      </Flex>
    );
  }

  if (hasReachedBudgetLimit && isFreeTier) {
    return (
      <Flex direction="column" gap="4">
        <Text>This feature requires a payment method.</Text>
        <Text>Upgrade your account to immediately unlock all features.</Text>
        <Button
          colorScheme="green"
          onClick={() => {
            history.push(Routes.dashboard_organization_billingWithId(selectedOrganization?.id || ""));
          }}
        >
          Unlock all features
        </Button>
      </Flex>
    );
  }

  if (hasReachedBudgetLimit && !isFreeTier) {
    return (
      <Flex direction="column" gap="4">
        <Text>You have reached the budget limit for your organization.</Text>
        <Text>Upgrade your account or contact support to create more deployments.</Text>
        <Button
          colorScheme="green"
          onClick={() => {
            history.push(Routes.dashboard_organization_billingWithId(selectedOrganization?.id || ""));
          }}
        >
          Upgrade your account
        </Button>
      </Flex>
    );
  }

  if (isDisabledForSingleProject) {
    return (
      <Flex direction="column" gap="4">
        <Text>You don't have permission to create a deployment in this project.</Text>
        <Text>Please contact your organization admin to request permission.</Text>
      </Flex>
    );
  }
  return null;
};
