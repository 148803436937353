//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchDiskPerformance } from "./useFetchDiskPerformance";
import { useFetchNodeSizes } from "./useFetchNodeSizes";

export const useReinitializeDiskPerformance = () => {
  const { values, setFieldValue } = useFormikContext<NewDeploymentFormValues>();
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const { data: diskPerformanceList, isRefetching } = useFetchDiskPerformance({
    regionId: values.regionId,
    modelId: values.modelId,
    nodeDiskSizeId: values.nodeSizeId,
    nodeDiskSize: nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size || 0,
  });
  const { isExisting } = useFetchDeploymentForEdit();

  useEffect(() => {
    // diskPerformanceList will change when values.regionId, values.modelId, values.nodeSizeId changes
    if (isRefetching || !diskPerformanceList?.items?.length || isExisting) {
      return;
    }
    const newDiskPerformanceId = diskPerformanceList?.items?.find((dp) => dp.is_default)?.id || "";
    if (newDiskPerformanceId) {
      setFieldValue("diskPerformanceId", newDiskPerformanceId);
    }
  }, [values.regionId, values.modelId, values.nodeSizeId, isRefetching, diskPerformanceList]);
};
