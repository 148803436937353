//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { SliderProps, Slider, SliderMark, SliderTrack, SliderFilledTrack, SliderThumb, FormErrorMessage } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import LabeledFormControl, { LabeledFormControlProps } from "./LabeledFormControl";

interface SliderControlProps extends LabeledFormControlProps {
  label: string;
  name: string;
  sliderProps?: SliderProps;
  min: number;
  max: number;
  minText: string;
  maxText: string;
  sliderValueText: string;
}

export const SliderControl = ({ label, name, sliderProps, min, max, minText, maxText, sliderValueText, ...rest }: SliderControlProps) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, { error, touched }] = useField(name);

  useEffect(() => {
    if (field.value < min) {
      setFieldValue(name, min);
    } else if (field.value > max) {
      setFieldValue(name, max);
    }
  }, [field.value]);

  return (
    <LabeledFormControl label={label} htmlFor={name} isInvalid={!!error && touched} isDisabled={isSubmitting} {...rest}>
      <Slider
        {...field}
        {...sliderProps}
        min={min}
        max={max}
        name={name}
        onChange={(val) => setFieldValue(name, val)}
        colorScheme="green"
        isDisabled={min === max}
      >
        <SliderMark key={min} value={min} marginTop="2" marginLeft="-5" fontSize="sm">
          {`${min} ${minText}`}
        </SliderMark>
        <SliderMark key={max} value={max} marginTop="2" marginLeft="-5" width="max-content" fontSize="sm">
          {`${max} ${maxText}`}
        </SliderMark>
        {
          <SliderMark value={field.value} textAlign="center" backgroundColor="green.500" color="white" marginTop="-10" marginLeft="-8" width="16">
            {`${field.value} ${sliderValueText}`}
          </SliderMark>
        }
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <FormErrorMessage>{error}</FormErrorMessage>
    </LabeledFormControl>
  );
};
