//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormErrorMessage, Grid, Input, InputProps } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import LabeledFormControl, { LabeledFormControlProps } from "./LabeledFormControl";

interface InputControlProps extends LabeledFormControlProps {
  label: string;
  name: string;
  inputProps?: InputProps;
}

const InputControl = ({ label, name, inputProps, ...rest }: InputControlProps) => {
  const [field, { error, touched }] = useField(name);

  const { isSubmitting } = useFormikContext();

  return (
    <LabeledFormControl label={label} htmlFor={name} isInvalid={!!error && touched} isDisabled={isSubmitting} {...rest}>
      <Grid gridTemplateRows="1fr auto">
        <Input {...inputProps} {...field} id={name} />
        <FormErrorMessage>{error}</FormErrorMessage>
      </Grid>
    </LabeledFormControl>
  );
};

export default InputControl;
