//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

import { useState, useEffect } from "react";
import apiClients from "../../api/apiclients";
import useOrganisationStore from "../../util/storage/OrganisationStore";

const useRegion = (regionId?: string) => {
  const setStoreRegion = useOrganisationStore((state) => state.setRegion);
  const storedRegion = useOrganisationStore((state) => state.getRegion(regionId || ""));

  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState(storedRegion || {});
  const [error, setError] = useState(false);

  const fetchData = async (id: string) => {
    setLoading(true);
    try {
      const regionResponse = await apiClients.platformClient.GetRegion({ id });
      setStoreRegion(regionResponse);
      setRegion(regionResponse);
      setError(false);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (regionId) fetchData(regionId);
    else setLoading(false);
  }, [regionId]);

  return { loading, region, error };
};

export default useRegion;
