import React, { useEffect, useState } from "react";
import { ErrorMessage, Section, SectionHead, SectionHeader } from "../../../ui/lib";
import { CreditBundlesList, CreditDebt } from "../../../api/credits/v1/credits";
import { useCreditsAndUsageAPI } from "./useCreditsAndUsageAPI";
import { CreditsStats } from "./CreditsBundleStats";

import { CreditsUsageGraph } from "./CreditUsageRateGraph";
import { useCheckPermissionByUrl } from "../../sidebar/useCheckPermissionByUrl";
import { RenderGuard } from "../../../util/RenderGuard";
import CreditsProjectionsGraph from "./CreditProjectionsGraph";
import { Box } from "../../../ui/_box";
import { ActiveCreditsBundlePurchaseView, ExpiredCreditsBundlePurchaseHistory } from "./CreditsBundlePurchaseHistory";
import { CreditsBundleCTAInfo } from "./CreditsBundleCTAInfo";
import { isEmpty } from "lodash";
import { CreditsBundleConsumptionList } from "./CreditsBundleConsumptionList";
import { CreditDebtView } from "./CreditsBundleDebtView";
import { useDashboardContext } from "../../DashboardContextProvider";

export const CreditsBundleView = () => {
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const { getCreditBundles, getCreditBundleDebt } = useCreditsAndUsageAPI();
  const { isFreeTier, isProTier } = useDashboardContext();

  const canGetConsumptionReport = !!checkOrgPermission("credit.creditusagereport.get");
  const canGetCreditsUsageGraph = !!checkOrgPermission("credit.creditusagereport.get");
  const canGetUsageProjections = !!checkOrgPermission("credit.creditbundleusageprojection.get");
  const hasCreditsViewPermission = !!checkOrgPermission("credit.creditbundle.list");
  const canGetCreditBundleDebtInfo = !!checkOrgPermission("credit.creditdebt.get");

  const [creditBundles, setCreditBundles] = useState<CreditBundlesList>();
  const [creditDebt, setCreditDebt] = useState<CreditDebt>();
  const [error, setError] = useState<string>("");

  const getCreditBundlesDetails = async () => {
    const { data = {}, error: errorWhenGettingPurchasedCreditBundle } = await getCreditBundles();

    if (errorWhenGettingPurchasedCreditBundle) {
      setError("Error getting the purchased credit bundles details");
      return;
    }
    setCreditBundles(data);
  };

  const getDebt = async () => {
    const { data, error: errorWhenGettingCreditDebt } = await getCreditBundleDebt();

    if (errorWhenGettingCreditDebt) {
      setError("Error getting the credit debt");
      return;
    }

    setCreditDebt(data);
  };

  useEffect(() => {
    getCreditBundlesDetails();
    getDebt();
  }, []);

  if (isFreeTier || isProTier || isEmpty(creditBundles)) {
    return (
      <Section>
        <SectionHead>
          <SectionHeader title={"Credit Bundles"} />
        </SectionHead>
        <CreditsBundleCTAInfo />
      </Section>
    );
  }

  return (
    <>
      <Section>
        <SectionHead>
          <SectionHeader title={"Credits & Usage"} />
        </SectionHead>
      </Section>

      <ErrorMessage active={!!error} message={error} />

      <RenderGuard renderIf={!creditDebt?.amount}>
        <CreditsStats creditBundle={creditBundles} />
      </RenderGuard>

      <RenderGuard renderIf={canGetCreditBundleDebtInfo}>
        <CreditDebtView creditDebt={creditDebt} />
      </RenderGuard>

      <RenderGuard renderIf={hasCreditsViewPermission && !isEmpty(creditBundles)}>
        <Box padding={"32px 0"}>
          <ActiveCreditsBundlePurchaseView creditBundles={creditBundles} />
          <ExpiredCreditsBundlePurchaseHistory creditBundles={creditBundles} />
        </Box>
      </RenderGuard>

      <RenderGuard renderIf={canGetCreditsUsageGraph && !isEmpty(creditBundles)}>
        <CreditsUsageGraph />
      </RenderGuard>

      <RenderGuard renderIf={canGetUsageProjections && !isEmpty(creditBundles) && !creditDebt?.amount}>
        <CreditsProjectionsGraph />
      </RenderGuard>

      <RenderGuard renderIf={canGetConsumptionReport}>
        <CreditsBundleConsumptionList />
      </RenderGuard>
    </>
  );
};
