//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useState, useEffect } from "react";
import apiClients from "../../api/apiclients";
import useOrganisationStore from "../../util/storage/OrganisationStore";

const useProject = (projectId: string) => {
  const setStoreProject = useOrganisationStore((state) => state.setProject);
  const storedProject = useOrganisationStore((state) => state.getProject(projectId || ""));

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(storedProject || {});
  const [error, setError] = useState(false);

  const fetchData = async (id: string) => {
    setLoading(true);
    try {
      const projectResponse = await apiClients.resourceManagerClient.GetProject({ id });
      setStoreProject(projectResponse);
      setProject(projectResponse);
      setError(false);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectId) fetchData(projectId);
    else setLoading(false);
  }, [projectId]);

  return { loading, project, error };
};

export default useProject;
