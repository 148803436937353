//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid, SwitchProps, Switch, FormErrorMessage } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import LabeledFormControl, { LabeledFormControlProps } from "./LabeledFormControl";

interface SwitchControlProps extends LabeledFormControlProps {
  label: string;
  name: string;
  switchProps?: SwitchProps;
}

export const SwitchControl = ({ label, name, switchProps, ...rest }: SwitchControlProps) => {
  const [field, { error, touched }] = useField(name);

  const { isSubmitting } = useFormikContext();

  return (
    <LabeledFormControl
      gridWrapperProps={{ gridTemplateColumns: "min-content 20px", marginBottom: "0" }}
      display="flex"
      alignItems="center"
      label={label}
      htmlFor={name}
      isInvalid={!!error && touched}
      isDisabled={isSubmitting}
      {...rest}
    >
      <Grid gridTemplateRows="1fr auto">
        <Switch {...field} id={name} {...switchProps} isChecked={field.value} />
        <FormErrorMessage>{error}</FormErrorMessage>
      </Grid>
    </LabeledFormControl>
  );
};
