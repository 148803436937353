//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormErrorMessage, Grid, Radio, RadioGroup, RadioGroupProps } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import LabeledFormControl, { LabeledFormControlProps } from "./LabeledFormControl";

interface RadioGroupControlProps extends LabeledFormControlProps {
  radioOptions: { value: string; label: string }[];
  label: string;
  name: string;
  radioGroupProps?: RadioGroupProps;
}

export const RadioGroupControl = ({ label, name, radioGroupProps, radioOptions, ...rest }: RadioGroupControlProps) => {
  const [field, { error, touched, initialValue }] = useField(name);
  const { onChange } = field;

  const { isSubmitting } = useFormikContext();

  return (
    <LabeledFormControl alignItems="center" label={label} htmlFor={name} isInvalid={!!error && touched} isDisabled={isSubmitting} {...rest}>
      <RadioGroup id={name} {...radioGroupProps} defaultValue={initialValue} {...field}>
        <Grid gap="2" display="flex" flexDirection="column">
          {radioOptions.map(({ label, value }) => (
            <Radio onChange={onChange} value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Grid>
      </RadioGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </LabeledFormControl>
  );
};
