//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import apiClients from "../../api/apiclients";
import { PageSpinner } from "../../components/PageSpinner";
import { useDisableTopMenu } from "../../components/useDisableTopMenu";
import { Routes } from "../../routes";
import { BudgetHelper } from "../BudgetHelper";
import { useDashboardContext } from "../DashboardContextProvider";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { NewDeploymentDisabledScreen } from "./NewDeploymentDisabledScreen";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { NewDeploymentFormContainer } from "./NewDeploymentFormContainer";
import { useFetchCACertificates } from "./useFetchCACertificates";
import { useFetchDBVersions } from "./useFetchDBVersions";
import { useFetchDefaultDBVersion } from "./useFetchDefaultDBVersion";
import { useFetchDeploymentFeatures } from "./useFetchDeploymentFeatures";
import { useFetchDeploymentModels } from "./useFetchDeploymentModels";
import { useFetchDeploymentProfiles } from "./useFetchDeploymentProfiles";
import { useFetchDiskPerformance } from "./useFetchDiskPerformance";
import { useFetchIAMProviders } from "./useFetchIAMProviders";
import { useFetchIPAllowlists } from "./useFetchIPAllowlists";
import { useFetchLimits } from "./useFetchLimits";
import { useFetchNodeSizes } from "./useFetchNodeSizes";
import { useFetchProviders } from "./useFetchProviders";
import { useFetchRegions } from "./useFetchRegions";
import { useFetchSupportPlans } from "./useFetchSupportPlans";
import { useFetchTermsAndConditions } from "./useFetchTermsAndConditions";

export const NewDeploymentPage = () => {
  useDisableTopMenu();
  const { isFreeTier, isPerpetualFreeTrialAvailable } = useDashboardContext();
  const { project, deployments, refetchDeployments } = useProjectDetailsContext();
  const toast = useToast();
  const history = useHistory();
  const { data: termsAndConditions, isLoading: isLoadingTermsAndConditions } = useFetchTermsAndConditions();

  const onSubmit = async (values: NewDeploymentFormValues) => {
    let nodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size;
    if (values.customDiskSize && values.customDiskSize[values.nodeSizeId]) {
      nodeDiskSize = values.customDiskSize[values.nodeSizeId];
    }
    const deploymentRequest = {
      project_id: project.id,
      name: values.name,
      description: values.description,
      region_id: values.regionId,
      support_plan_id: values.supportPlanId,
      version: values.dbVersion,
      custom_image: values.customImage,
      certificates: { ca_certificate_id: values.certificate },
      model: { model: values.modelId, node_size_id: values.nodeSizeId, node_disk_size: nodeDiskSize, node_count: values.numberOfNodes },
      servers: {},
      is_platform_authentication_enabled: true,
      accepted_terms_and_conditions_id: termsAndConditions?.id,
      disk_performance_id: values.diskPerformanceId,
      ipallowlist_id: values.ipAllowlistId,
      iamprovider_id: values.iamProviderId,
    };
    try {
      const createdDeployment = await apiClients.dataClient.CreateDeployment(deploymentRequest);
      toast({
        title: "Deployment created",
        description: `Deployment "${values.name}" has been created`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // redirect to the deployment details page
      history.replace(Routes.dashboard_project_deployment_detailsWithId(project.id || "", createdDeployment.id || ""));
      refetchDeployments();
      return createdDeployment;
    } catch (error) {
      toast({
        title: "Failed to create deployment",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const { data: providers, isLoading: isLoadingProviders } = useFetchProviders();
  const initialProviderId = providers?.items?.[0]?.id || "";

  const { data: availableModels, isLoading: isLoadingModels } = useFetchDeploymentModels();
  let initialModelId = "";
  if (isFreeTier && isPerpetualFreeTrialAvailable) {
    if (availableModels?.items?.find((model) => model.id === "free")) {
      initialModelId = "free";
    }
  } else {
    initialModelId = "oneshard"; // oneshard is default in current screens
  }

  const { data: regions, isLoading: isLoadingRegions } = useFetchRegions({
    providerId: initialProviderId,
    modelId: initialModelId,
  });
  const initialRegionId =
    regions?.items?.find((region) => {
      return !region.out_of_stock && !region.low_stock && region.available && !region.prerelease;
    })?.id ||
    regions?.items?.[0]?.id ||
    "";

  const {
    data: nodeSizes,
    status: nodeSizeStatus,
    fetchStatus: nodeSizeFetchStatus,
  } = useFetchNodeSizes({
    regionId: initialRegionId,
    modelId: initialModelId,
  });
  const firstNodeSize = nodeSizes?.items?.[0];
  const initialNodeSizeId = firstNodeSize?.id || "";
  const { isLoading: isLoadingDefaultDBVersion, data: defaultVersion } = useFetchDefaultDBVersion();
  const { isLoading: isLoadingDBVersions } = useFetchDBVersions();
  const { data: caCertificates, isLoading: isLoadingCACertificates } = useFetchCACertificates();

  const initialCaCertificate = caCertificates?.items?.find((certificate) => certificate.is_default)?.id || caCertificates?.items?.[0]?.id || "";

  const { isLoading: isLoadingIPAllowlists } = useFetchIPAllowlists();
  const { data: supportPlans, isLoading: isLoadingSupportPlans } = useFetchSupportPlans({
    modelId: initialModelId,
  });
  const defaultSupportPlanId = supportPlans?.items?.find((p) => p.is_default)?.id || "";
  const { isLoading: isLoadingDeploymentProfiles } = useFetchDeploymentProfiles();
  const { status: deploymentFeaturesStatus, fetchStatus: deploymentFeaturesFetchStatus } = useFetchDeploymentFeatures({
    modelId: initialModelId,
    regionId: initialRegionId,
    nodeSizeId: initialNodeSizeId,
  });
  const {
    status: diskPerformanceStatus,
    fetchStatus: diskPerformanceFetchStatus,
    data: diskPerformances,
  } = useFetchDiskPerformance({
    modelId: initialModelId,
    regionId: initialRegionId,
    nodeDiskSizeId: initialNodeSizeId,
    nodeDiskSize: nodeSizes?.items?.find((ns) => ns.id === initialNodeSizeId)?.min_disk_size || 0,
  });
  const { isLoading: isLoadingIAMProviders } = useFetchIAMProviders();
  const { isLoading: isLoadingLimits } = useFetchLimits({ regionId: initialRegionId });
  const initialDiskPerformanceId = diskPerformances?.items?.find((dp) => dp.is_default)?.id || "";
  const isLoading =
    isLoadingProviders ||
    isLoadingRegions ||
    isLoadingModels ||
    (nodeSizeStatus === "loading" && nodeSizeFetchStatus === "fetching") ||
    isLoadingDBVersions ||
    isLoadingDefaultDBVersion ||
    isLoadingCACertificates ||
    isLoadingIPAllowlists ||
    isLoadingSupportPlans ||
    isLoadingDeploymentProfiles ||
    (deploymentFeaturesStatus === "loading" && deploymentFeaturesFetchStatus === "fetching") ||
    isLoadingIAMProviders ||
    (diskPerformanceStatus === "loading" && diskPerformanceFetchStatus === "fetching") ||
    isLoadingLimits ||
    isLoadingTermsAndConditions;

  if (isLoading) {
    return (
      <Box height="100vh">
        <PageSpinner isLoading height="calc(100% - 40px)" />
      </Box>
    );
  }

  if (BudgetHelper.disableDeploymentCreate(deployments)) {
    return <NewDeploymentDisabledScreen />;
  }
  return (
    <Formik<NewDeploymentFormValues>
      enableReinitialize
      initialValues={{
        name: "New deployment",
        description: "",
        providerId: initialProviderId,
        regionId: initialRegionId,
        modelId: initialModelId,
        nodeSizeId: initialNodeSizeId,
        numberOfNodes: 3,
        dbVersion: defaultVersion?.version || "",
        certificate: initialCaCertificate,
        ipAllowlistId: "",
        supportPlanId: defaultSupportPlanId,
        deploymentProfileId: "",
        diskPerformanceId: initialDiskPerformanceId,
        customImage: "",
        iamProviderId: "",
        customDiskSize: {},
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        providerId: Yup.string().required("Required"),
        regionId: Yup.string().required("Required"),
        description: Yup.string(),
        modelId: Yup.string().required("Required"),
        nodeSizeId: Yup.string().required("Required"),
        numberOfNodes: Yup.number().required("Required"),
        dbVersion: Yup.string().required("Required"),
        certificate: Yup.string().required("Required"),
        supportPlanId: Yup.string().required("Required"),
        ipAllowlistId: Yup.string(),
        deploymentProfileId: Yup.string(),
        diskPerformanceId: Yup.string().required("Required"),
        customImage: Yup.string(),
        iamProviderId: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      <NewDeploymentFormContainer />
    </Formik>
  );
};
