import {
  GetCreditBundleUsageProjectionRequest,
  ListCreditBundleUsageRequest,
  ListCreditBundlesRequest,
  ListCreditUsageReportsRequest,
} from "../../../api/credits/v1/credits";
import apiClients from "../../../api/apiclients";
import { useDashboardContext } from "../../DashboardContextProvider";
import { IDOptions, ListOptions } from "../../../api/lib";
import Downloader from "js-file-downloader";

export const useCreditsAndUsageAPI = () => {
  const { selectedOrganization } = useDashboardContext();

  const getCreditBundles = async () => {
    const request: ListCreditBundlesRequest = {
      organization_id: selectedOrganization?.id || "",
      exclude_expired: false,
    };
    try {
      const data = await apiClients.creditBundleClient.ListCreditBundles(request);
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const getCreditBundleDebt = async () => {
    const request: IDOptions = {
      id: selectedOrganization?.id || "",
    };
    try {
      const data = await apiClients.creditBundleClient.GetOrganizationCreditDebt(request);
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const getUsageDataFromRange = async (start: string, end: string, page: ListOptions) => {
    try {
      const req: ListCreditBundleUsageRequest = {
        organization_id: selectedOrganization?.id || "",
        starts_at: new Date(start),
        ends_at: new Date(end),
        options: {
          ...page,
        },
      };
      const { items = [] } = await apiClients.creditBundleClient.ListCreditBundlesUsage(req);
      return { data: items };
    } catch (error) {
      return { error };
    }
  };

  const getCreditBundlePDFReportForMonth = async (id: string) => {
    try {
      const req: IDOptions = {
        id,
      };
      const stream = await apiClients.creditBundleClient.GetCreditUsageReportPDF(req, async (evt) => {
        const content = evt.message?.contents;
        if (content) {
          const decoded = atob(content);
          const bytes = new Array(decoded.length);
          for (let i = 0; i < decoded.length; ++i) {
            bytes[i] = decoded.charCodeAt(i);
          }
          const blob = new Blob([new Uint8Array(bytes)], { type: "application/pdf" });
          await new Downloader({
            url: window.URL.createObjectURL(blob),
            filename: evt.message?.filename || "report.pdf",
          });
        }
      });

      await stream.closed;
      return { data: "success" };
    } catch (error) {
      return { error };
    }
  };

  const getCreditBundleReportIdForRange = async (payload?: ListCreditBundleUsageRequest) => {
    try {
      let req: ListCreditUsageReportsRequest = {
        organization_id: selectedOrganization?.id || "",
      };
      if (payload) {
        req = {
          ...payload,
          ...req,
        };
      }
      const { items = [] } = await apiClients.creditBundleClient.ListCreditUsageReports(req);
      return { data: items };
    } catch (error) {
      return { error };
    }
  };

  const getCreditBundleProjectionForFutureX = async (limit: number) => {
    const req: GetCreditBundleUsageProjectionRequest = {
      organization_id: selectedOrganization?.id || "",
      projections_limit: limit,
    };

    try {
      const { projections = [] } = await apiClients.creditBundleClient.GetCreditBundleUsageProjection(req);
      return { data: projections };
    } catch (error) {
      return { error };
    }
  };

  return {
    getCreditBundles,
    getUsageDataFromRange,
    getCreditBundlePDFReportForMonth,
    getCreditBundleProjectionForFutureX,
    getCreditBundleReportIdForRange,
    getCreditBundleDebt,
  };
};
