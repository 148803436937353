//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Grid } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";

export const StyledCard = styled(Card)`
  &&& {
    min-height: 240px;
    width: 100%;
  }
`;

const SampleDataSetCard = () => {
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "", status = {} } = deployment;
  const { endpoint_default: deploymentEndpoint = "" } = status;
  const { exampleDbName, loading } = useExampleDatabaseName(deploymentId);

  const onOpenEndpoint = () => {
    if (!exampleDbName) {
      return window.open(deploymentEndpoint);
    }

    const url = new URL(deploymentEndpoint);
    url.port = "";
    url.pathname = `_db/${exampleDbName}`;
    window.open(url);
  };

  return (
    <StyledCard>
      <Card.Content>
        <Card.Header>Sample Data Set</Card.Header>
        <Card.Description>
          <p className="para">Need help with sample dataset for deployment?</p>
          <p className="para">Click on database UI to access the dataset. {exampleDbName && `(Added to "${exampleDbName}" database)`}</p>
        </Card.Description>
      </Card.Content>
      <Card.Content textAlign="center" extra>
        <Button loading={loading} secondary content="Open database UI" onClick={onOpenEndpoint} />
      </Card.Content>
    </StyledCard>
  );
};
const LoadDataCard = () => {
  return (
    <StyledCard>
      <Card.Content>
        <Card.Header>Load Data</Card.Header>
        <Card.Description>
          <p className="para">Do you have your own dataset?</p>
          <p className="para">Select your dataset to upload.</p>
        </Card.Description>
      </Card.Content>
      <Card.Content textAlign="center" extra>
        <Link to="details#dataloader">
          <Button secondary content="Load Data" />
        </Link>
      </Card.Content>
    </StyledCard>
  );
};
const TutorialsCard = () => {
  return (
    <StyledCard>
      <Card.Content>
        <Card.Header>Tutorials</Card.Header>
        <Card.Description>
          <p className="para">Explore ArangoDB features using data sets</p>
        </Card.Description>
      </Card.Content>
      <Card.Content textAlign="center" extra>
        <a target="_blank" href="https://developer.arangodb.com/docs/query/introduction" rel="noreferrer">
          <Button secondary content="Querying DB" />
        </a>
      </Card.Content>
    </StyledCard>
  );
};
const useExampleDatabaseName = (deploymentId: string | undefined) => {
  const [exampleDbName, setExampleDbName] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const idOptions = {
        deployment_id: deploymentId,
      };
      const list = await apiClients.exampleClient.ListExampleDatasetInstallations(idOptions);
      setLoading(false);
      setExampleDbName(list.items?.[0].status?.database_name);
    };
    if (deploymentId) {
      fetchData();
    }
  }, [deploymentId]);
  return { exampleDbName, loading };
};
const LearningContentSections = () => {
  return (
    <Grid padded="vertically" columns="3" stackable>
      <Grid.Column>
        <SampleDataSetCard />
      </Grid.Column>

      <Grid.Column>
        <LoadDataCard />
      </Grid.Column>

      <Grid.Column>
        <TutorialsCard />
      </Grid.Column>
    </Grid>
  );
};

export { LearningContentSections };
