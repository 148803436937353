import { Segment, Button } from "semantic-ui-react";
import React from "react";

export const CreditsBundleCTAInfo = () => {
  return (
    <Segment>
      <p className="para">
        The ArangoGraph credit model is a versatile prepaid model that allows you to purchase credits and use them in a flexible way, based on what you
        haverunning in ArangoGraph. Instead of purchasing a particular deployment for a year, you can purchase a number of ArangoGraph credits that expire a
        year after purchase. These credits are then consumed over that time period, based on the deployments you run in ArangoGraph. To purchase credits for
        your organization, you need to get in touch with the ArangoDB team.
      </p>

      <Button as="a" href="https://www.arangodb.com/contact" target="_blank" primary>
        Contact us
      </Button>
    </Segment>
  );
};
