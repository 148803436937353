//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import styled from "@emotion/styled";
import { TextLink, IChildrenView } from "../ui/lib";
import { SignupModalView } from "./SignupModalView";
import { Routes } from "../routes";
import { HelpUrl } from "../HelpUrl";
import { ITracking } from "../tracking/api";

import arangoGraphLogo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangographinsights-logo.png";
import powered_by_cilium from "./powered_by_cilium.png";
import { PersistentState } from "../util/PersistentState";
import { useLocation } from "react-router-dom";
import { FlexBox } from "../ui/_flex";
import logo3 from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../ui/_box";

export const PoweredByCiliumContainer = styled("div")`
  margin-top: 20px;
  img {
    width: 60%;
    margin-left: auto;
  }
`;

export const RelativeContainer = styled(Container)`
  position: relative;
`;

interface IFooterViewArgs {
  isAuthenticated: boolean;
  onClickLogin?: () => void;
  onClickSignup?: () => void;
  onClickSupportRequest?: () => void;
}

const FooterRow = styled(Grid.Row)`
  padding: 16px;
  a {
    border-style: none !important;
  }
`;

const FooterBox = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  height: auto;
  background-color: var(--gray-200);
  margin-top: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  .container {
  }
`;

export const FooterView = ({ ...args }: IFooterViewArgs) => (
  <FooterBox>
    <Container>
      <Grid columns="16" stackable>
        <FooterRow>
          <Grid.Column width="3">
            <p className="para">
              <a href={HelpUrl.url} target="_blank" rel="noreferrer">
                Documentation
              </a>
            </p>
            <p className="para">
              <a href="/faq">Frequently asked questions</a>
            </p>
            <p className="para">
              <a href="/support">Support plans</a>
            </p>
            <p className="para">
              <a href="/regions">Available Regions</a>
            </p>
            <p className="para">
              <a href={HelpUrl.training_center} target="_blank" rel="noreferrer">
                Training center
              </a>
            </p>
            {!args.isAuthenticated && (
              <p className="para">
                <TextLink
                  onClick={() => {
                    window.open("https://www.arangodb.com/contact/");
                  }}
                >
                  Request help
                </TextLink>
              </p>
            )}
            <p className="para">
              <a href={HelpUrl.status_page} target="_blank" rel="noreferrer">
                Status
              </a>
            </p>
            <p className="para">
              <a href="https://arangodb.com" target="_blank" rel="noreferrer">
                arangodb.com
              </a>
            </p>
          </Grid.Column>
          <Grid.Column width="4">
            <p className="para">
              <a href="/terms-and-conditions">Terms &amp; conditions</a>
            </p>
            <p className="para">
              <a href="/privacy-policy">Privacy policy</a>
            </p>
            <p className="para">
              <a href="/data-processing-addendum">Data Processing Addendum</a>
            </p>
          </Grid.Column>
          <Grid.Column width="3">
            {!args.isAuthenticated && (
              <div>
                {args.onClickLogin && (
                  <p className="para">
                    <TextLink onClick={args.onClickLogin}>Log in</TextLink>
                  </p>
                )}
                {args.onClickSignup && (
                  <p className="para">
                    <TextLink onClick={args.onClickSignup}>Sign up</TextLink>
                  </p>
                )}
              </div>
            )}
          </Grid.Column>
          <Grid.Column width="1" only="computer tablet" />
          <Grid.Column width="5" textAlign="right">
            <img src={arangoGraphLogo} alt="ArangoGraph" title="ArangoGraph" />

            <PoweredByCiliumContainer>
              <a href="https://cilium.io" target="_blank" rel="noreferrer">
                <img src={powered_by_cilium} alt="Powered by Cilium" title="Powered by Cilium" />
              </a>
            </PoweredByCiliumContainer>
          </Grid.Column>
        </FooterRow>
      </Grid>
    </Container>
  </FooterBox>
);

export interface IHomeTemplateViewArgs extends IChildrenView {
  tracking: ITracking;
  isAuthenticated: boolean;
  loginWithSSO?: (organizationId?: string) => void;
  onClickLogin?: () => void;
  onClickSignup?: () => void;
  onSignup?: () => void;
  onClickLogout?: () => void;
  signupModalOpen: boolean;
  onCloseSignupModal: () => void;
}

export const NavHolder = styled("div")`
  min-height: 100%;
  height: 100%;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: auto;
    right: auto;
    width: 100%;
    height: 100vh;
  }
  & a {
    font-size: 1.15rem;
    font-weight: 500;

    &:hover {
      color: var(--green-700);
    }
  }
`;

export const GridRowIntro = styled(Grid.Row)`
  margin-top: 92px;
`;

export const TransparentLogo1 = styled("img")`
  opacity: 0.13;
  position: absolute;
  left: -150px;
  top: -40px;
  width: 50%;
  z-index: 2;
`;

export const TransparentLogo2 = styled("img")`
  opacity: 0.05;
  position: absolute;
  left: 250px;
  top: -70px;
  width: 30%;
  z-index: 1;
`;

const Actions = ({ ...args }: IHomeTemplateViewArgs) => {
  return (
    <FlexBox>
      {!args.isAuthenticated && args.onClickLogin && (
        <Box padding={"0 10px 0 0"}>
          <Button onClick={args.onClickLogin} size="medium">
            Log in
          </Button>
        </Box>
      )}
      <Box>
        {!args.isAuthenticated && args.onClickSignup && args.onSignup && (
          <Button primary onClick={args.onClickSignup} size="medium">
            Start Free
          </Button>
        )}
      </Box>
    </FlexBox>
  );
};

const HomeTemplateView = ({ ...args }: IHomeTemplateViewArgs) => {
  const { search } = useLocation();

  useEffect(() => {
    if (!PersistentState.isUserLoggedIn()) {
      if (PersistentState.isRequestRedirectionPending(Routes.requestHelp)) {
        window.location.href = "https://www.arangodb.com/contact/";
        PersistentState.removeRedirection();
      }

      if (PersistentState.isRequestRedirectionPending(Routes.signup)) {
        const params = new URLSearchParams(search);
        const areTermsAndConditionsAccepted = params.get("acceptedTermsAndConditions") === "1";
        if (areTermsAndConditionsAccepted) {
          args.tracking.trackAcceptedTerms();
          args.onSignup && args.onSignup();
        } else {
          args.onClickSignup && args.onClickSignup();
        }

        PersistentState.removeRedirection();
      }

      if (PersistentState.isRequestRedirectionPending(Routes.login)) {
        const params = new URLSearchParams(search);
        const orgParam = params.get("organizationId");
        if (orgParam) {
          args.loginWithSSO && args.loginWithSSO(orgParam as string);
        } else {
          args.onClickLogin && args.onClickLogin();
        }

        PersistentState.removeRedirection();
      }
    }
  }, []);

  const isAuthenticated = args.isAuthenticated;

  return (
    <Box>
      <NavHolder className="container">
        <Container>
          <FlexBox justify="space-between" align="center" padding={"10px 10px 10px 0"} margin={"10px 0 0 0"} fontSize={"1.2rem"} fontWeight={"400"} wrap="wrap">
            <Box position="relative" top={"3px"}>
              <a href="/">
                <img src={logo3} alt="ArangoGraph" title="ArangoGraph" width={200} />
              </a>
            </Box>
            <a href={"https://arangodb.com"} target="_blank" rel="noreferrer" className="nav-item">
              <Box padding={"10px"}>ArangoDB</Box>
            </a>
            <a href={HelpUrl.url} target="_blank" rel="noreferrer" className="nav-item">
              <Box padding={"10px"}>Documentation</Box>
            </a>
            <a href={"/faq"} target="_blank" rel="noreferrer" className="nav-item">
              <Box padding={"10px"}>FAQ</Box>
            </a>
            {!isAuthenticated && (
              <a href="https://www.arangodb.com/contact/" target="requestHelp" rel="noreferrer" className="nav-item">
                <Box padding={"10px"}>Request help</Box>
              </a>
            )}
            <Actions {...args} />
          </FlexBox>
        </Container>
        {args.onSignup && <SignupModalView {...args} onSignup={args.onSignup} />}
      </NavHolder>
      <div>{args.children}</div>
      <FooterView {...args} />
    </Box>
  );
};

export { HomeTemplateView };
