import React, { useEffect } from "react";
import { ErrorMessage, ListActionDownload, Loading, PagingButtons, Section, SectionHead, SectionHeader, UnderlinedH3 } from "../../../ui/lib";
import { Card, Grid } from "semantic-ui-react";
import moment from "moment";
import { useCreditsAndUsageAPI } from "./useCreditsAndUsageAPI";
import { CreditUsageReport } from "../../../api/credits/v1/credits";
import { Box } from "../../../ui/_box";
import usePagination from "../../../ui/hooks/usePagination";
import { RenderGuard } from "../../../util/RenderGuard";

export const CreditsBundleConsumptionList = () => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [downloadingReportId, setDownloadingReportId] = React.useState<string>("");
  const { getCreditBundleReportIdForRange, getCreditBundlePDFReportForMonth } = useCreditsAndUsageAPI();
  const [usageItems, setUsageItems] = React.useState<CreditUsageReport[]>([]);
  const { paginationObject, setNextPage, setPreviousPage } = usePagination();

  const getReports = async () => {
    setProcessing(true);
    setError("");

    const { data = [], error: errorWhenGettingReport } = await getCreditBundleReportIdForRange({
      options: {
        page: paginationObject.page,
        page_size: paginationObject.pageSize,
      },
    });

    if (errorWhenGettingReport) {
      setError("Error getting the usage metrics for this month");
      setProcessing(false);
      return;
    }

    if (data.length) {
      setUsageItems(data);
    }
    setProcessing(false);
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    getReports();
  }, [paginationObject.page]);

  if (usageItems.length === 0) {
    return null;
  }

  const handleDownloadClick = async (item: CreditUsageReport) => {
    setError("");
    setDownloadingReportId(item.id || "");
    const { error } = await getCreditBundlePDFReportForMonth(item.id || "");
    if (error) {
      setError("Error downloading the report");
      return;
    }
    setDownloadingReportId("");
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Credits bundle consumption">
          <PagingButtons
            page={paginationObject.page}
            count={usageItems.length}
            onNextPage={setNextPage}
            onPreviousPage={setPreviousPage}
            size="mini"
            pageSize={paginationObject.pageSize}
          />
        </SectionHeader>
      </SectionHead>
      <Box padding={"32px 0"}>
        <RenderGuard renderIf={processing}>
          <Loading />
        </RenderGuard>
        <RenderGuard renderIf={!processing}>
          <ErrorMessage active={!!error} message={error} />
          <Card.Group itemsPerRow="1">
            {usageItems.map((item) => {
              return (
                <Card>
                  <Card.Content>
                    <Grid width="16">
                      <Grid.Column width="4">
                        <UnderlinedH3>Number</UnderlinedH3>
                        <div>{item.amount}</div>
                      </Grid.Column>
                      <Grid.Column width="4">
                        <UnderlinedH3>Date</UnderlinedH3>
                        <div>{moment(item.created_at).format("MMM DD, YYYY")}</div>
                      </Grid.Column>
                      <Grid.Column width="4">
                        <UnderlinedH3>Amount</UnderlinedH3>
                        <div>{item.closing_balance}</div>
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <ListActionDownload
                          loading={downloadingReportId === item.id}
                          disabled={downloadingReportId === item.id}
                          onClick={() => handleDownloadClick(item)}
                        />
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        </RenderGuard>
      </Box>
    </Section>
  );
};
