//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Grid, Message } from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh } from "../../util/WithRefresh";
import {
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
  TextLink,
  GridTitle,
  GridContainer,
  StyledGridRow,
  StyledGridColumn,
  ErrorMessage,
  StyledGridHeaderRow,
  PagingButtons,
} from "../../ui/lib";
import { Deployment, Organization, ListDeploymentsByFilterRequest } from "../../api/lib";
import { DeploymentStatusView } from "../deployment/DeploymentStatusView";
import ProjectLink from "./ProjectLink";
import ProviderRegion from "./ProviderRegion";
import apiClients from "../../api/apiclients";
import { Loading } from "../../ui/_loading";
import { HistoryHelper } from "../HistoryHelper";
import { Routes } from "../../routes";
import usePagination from "../../ui/hooks/usePagination";
import { useWithRefresh } from "../../util/WithRefreshContext";

interface IDeploymentListViewProps extends RouteComponentProps {
  organization: Organization;
}

const DeploymentListView = ({ organization, history }: IDeploymentListViewProps) => {
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const { subscribeUrl, unsubscribe } = useWithRefresh();
  const [organizationDeployments, setOrganizationDeployments] = useState<Deployment[]>([]);
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>(true);
  const [organizationError, setOrganizationError] = useState<string | null>(null);
  const { paginationObject, setNextPage, setPreviousPage } = usePagination();
  const { page, pageSize } = paginationObject;
  const onSelectProject = (id: string) => {
    HistoryHelper.push(history, Routes.dashboard_projectWithId(id));
  };

  const onSelectDeployment = (projectId: string, deploymentId: string) => {
    HistoryHelper.push(history, Routes.dashboard_project_deployment_detailsWithId(projectId, deploymentId));
  };

  const fetchDeployments = async () => {
    try {
      const req: ListDeploymentsByFilterRequest = {
        organization_id: organization.id,
        options: {
          page,
          page_size: pageSize,
        },
      };

      const { items } = (await apiClients.dataClient.ListDeploymentsByFilter(req)) || {};

      setOrganizationDeployments(items || []);
      setOrganizationError(null);
    } catch (error) {
      setOrganizationError(
        "A list of deployments cannot be loaded at the moment. Please try refreshing the page. If the issue persist, contact our Support team."
      );
    }
    setLoadingOrganization(false);
  };

  const reloadDeploymentInfo = async () => {
    if (organization) {
      fetchDeployments();
    } else {
      setOrganizationDeployments([]);
    }
  };

  const handleDeploymentSubscription = async () => {
    if (subscriptionId && unsubscribe) {
      await unsubscribe(subscriptionId);
    }

    const orgURL = organization.url;
    if (subscribeUrl) {
      const id = await subscribeUrl(reloadDeploymentInfo, orgURL);
      setSubscriptionId(String(id));
    }
  };

  useEffect(() => {
    handleDeploymentSubscription();

    return () => {
      unsubscribe && unsubscribe(subscriptionId);
    };
  }, []);

  useEffect(() => {
    handleDeploymentSubscription();
  }, [page]);

  if (loadingOrganization) return <Loading />;

  if (organizationDeployments.length === 0) {
    return (
      <Section>
        <ErrorMessage active={!!organizationError} onDismiss={() => setOrganizationError(null)} message={organizationError} />
        <SectionHead>
          <SectionHeader title={<span>Deployments</span>} />
        </SectionHead>
        <SectionContent>
          <Message>{organizationError ? "No deployments available" : "You don't have any deployments yet."}</Message>
        </SectionContent>
      </Section>
    );
  }

  return (
    <Section>
      <SectionHead>
        <SectionHeader title={<span>Deployments</span>}>
          <PagingButtons
            testId="pagingButtons"
            page={page}
            count={organizationDeployments.length}
            onNextPage={() => {
              setNextPage();
            }}
            onPreviousPage={setPreviousPage}
            size="mini"
            pageSize={pageSize}
          />
        </SectionHeader>
      </SectionHead>
      <SectionContent>
        <GridContainer>
          <Grid columns="4">
            <StyledGridHeaderRow>
              <Grid.Column width={5}>
                <GridTitle>Name</GridTitle>
              </Grid.Column>
              <Grid.Column width={5}>
                <GridTitle>Project</GridTitle>
              </Grid.Column>
              <Grid.Column width={4}>
                <GridTitle>Provider</GridTitle>
              </Grid.Column>
              <Grid.Column width={2}>
                <GridTitle>Status</GridTitle>
              </Grid.Column>
            </StyledGridHeaderRow>
            {organizationDeployments.map(({ id, name, status, project_id, region_id, ...deployment }) => (
              <StyledGridRow key={id}>
                <StyledGridColumn className="ellipsis" width={5}>
                  <TextLink disabled={!id} onClick={() => onSelectDeployment(project_id || "", id || "")}>
                    {name}
                  </TextLink>
                </StyledGridColumn>
                <StyledGridColumn className="ellipsis" width={5}>
                  <ProjectLink id={project_id} onSelect={() => onSelectProject(project_id || "")} />
                </StyledGridColumn>
                <StyledGridColumn width={4}>
                  <ProviderRegion regionId={region_id || ""} />
                </StyledGridColumn>
                <StyledGridColumn width={2}>
                  <DeploymentStatusView icons deployment={deployment} status={status} />
                </StyledGridColumn>
              </StyledGridRow>
            ))}
          </Grid>
        </GridContainer>
      </SectionContent>
    </Section>
  );
};

export default withRefresh()(DeploymentListView);
