//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export const PageSpinner = ({ isLoading, height = "100%" }: { isLoading: boolean; height?: string }) => {
  if (!isLoading) return null;

  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height={height}
      backgroundColor="rgba(255, 255, 255, 0.5)"
      zIndex="1000"
      justifyContent="center"
      alignItems="center"
      color="green.600"
    >
      <Spinner />
    </Flex>
  );
};
